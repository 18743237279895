import React, { Component } from "react";
import { withSnackbar } from "notistack";
import CesiumGlobeReborn from "components/Dashboard3D/CesiumGlobeReborn";

import { AppContext } from "AppProvider";
import { point, featureCollection, getCoord, midpoint } from "@turf/turf";
import "cesium/Build/Cesium/Widgets/widgets.css";

import {
  Ellipsoid,
  Cartesian3,
  Color,
  HeightReference,
  ScreenSpaceEventType,
  SingleTileImageryProvider,
  Rectangle,
  EllipsoidTerrainProvider,
  CesiumTerrainProvider,
  IonResource,
  Cartographic,
  createOsmBuildings,
  Ion,
  Cesium3DTileset,
  Cesium3DTileStyle,
  GeoJsonDataSource,
  KmlDataSource,
  HeadingPitchRoll,
  Transforms
} from "cesium/Build/Cesium/Cesium";
import {
  createIntermediateEntity,
  getDatasourceLayerByName,
  changePolylineMaterialByName,
  raiseToTopImageryLayerByName,
  radianToDegrees,
  degreesToRadians,
  getColorByString,
  createFlagEntity,
  changeFlagAlphaById,
  drawSVPoint,
  removeMapEntityById,
  getCesiumTooltip,
  getCartographic,
  setCesiumBackgroundTransparency
} from "./components/Dashboard3D/CesiumUtils";

import { rgbaFromString } from "components/Dashboard3D/ColorUtils";
import {
  loadTMSWithColor,
  addColoredImageryLayer,
  removeCesiumImageryLayerByName,
  getImageryLayersByName
} from "components/Dashboard3D/CesiumLayerUtils";
import { find3DTilesByName } from "components/Dashboard3D/Cesium3DTiles";

import { withTranslation } from "react-i18next";
import {
  projectVectorName,
  optimalPathVectorName,
  intermediatePointName,
  startPointScenarioName,
  startPointProjectName,
  endPointScenarioName,
  endPointProjectName,
  towerName,
  corridorLayerName,
  comparisonMapLayerName,
  resistanceLayerName,
  rasterLayerName,
  lineName
} from "./components/Dashboard3D/CesiumConstants";

import { getPalette } from "services/layer";
import { generateMapScreenshot, saveBlobAsImage } from "./utils/WatermarkTools";
/**
 * Component for the 3d Cesium map
 */

const Map3DContext = React.createContext();
export { Map3DContext };

class Map3DProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rasterLayers: [], // Array with raster layers
      cameraMode: "globe",
      drawpoints: false,
      geoprocess: [],
      corridors: [],
      comparisonMaps: [],
      modelFiles: "url", // [ url , api ]//you can change this var to get the models files from API as arraybuffer or use direct URL
      resistances: [],
      JSONdata: [],
      toggle3DToolbar: [],
      terrainAsset: 1,
      models: null,
      isStreeView: false,
      svCoord: [],
      openCableErrors: false,
      cesiumViewer: null,
      needTowerRedraw: true
    };
  }

  /**
   * 3d activated toolbox
   * @param {*} toolbar
   */
  set3DToolbar = toolbar => {
    // Clean StreeView. Measure/Pylons and StreeView are not compatible at the same time
    this.cleanSVPoint(true);
    let arr = this.state.toggle3DToolbar;
    if (arr.includes(toolbar)) {
      arr = arr.filter(t => t !== toolbar);
    } else {
      arr.push(toolbar);
    }
    this.setState({ toggle3DToolbar: arr });
  };

  componentDidMount() {
    this.context.listProjects();
    this.cesiumContainer = document.querySelector("#cesiumContainer");
    // this.state.cesiumViewer = undefined;
  }

  /**
   * refresh Wire.
   * @return null
   */
  refreshWireSource = value => {
    this.setState({ needTowerRedraw: value });
  };

  /**
   * Remove comparison map
   * @return null
   */
  removeComparisonMap = comparisonMap => {
    let temp = this.state.comparisonMaps;
    let l = temp.length;
    if (l > 0) {
      for (let index = 0; index < temp.length; index++) {
        if (temp[index].id === comparisonMap) {
          removeCesiumImageryLayerByName(
            this.state.cesiumViewer,
            comparisonMapLayerName + temp[index].id
          );
          temp.splice(index, 1);
        }
      }
      this.setState({ comparisonMaps: temp });
    }
  };

  /**
   *  update layers    .
   * @return null
   */
  removeCorridor = corridor => {
    let temp = this.state.corridors;
    let l = temp.length;
    if (l > 0) {
      for (let index = 0; index < temp.length; index++) {
        if (temp[index].id === corridor) {
          removeCesiumImageryLayerByName(
            this.state.cesiumViewer,
            corridorLayerName + temp[index].id
          );
          temp.splice(index, 1);
        }
      }
      this.setState({ corridors: temp });
    }
  };

  /**
   *  update layers    .
   * @return null
   */
  removeGeoProcess = geoproc => {
    let temp = this.state.geoprocess;
    let l = temp.length;
    let index;
    if (l > 0) {
      for (index = 0; index < temp.length; index++) {
        if (temp[index].id === geoproc) {
          temp.splice(index, 1);
        }
      }
      this.setState({ geoprocess: temp });
    }
  };

  /**
   * Export Optimal Path
   */
  exportOptimalPath = () => {
    let features = [];

    if (this.state.JSONdata.length >= 1) {
      let index;
      for (index = 0; index < this.state.JSONdata.length; index++) {
        if (this.state.JSONdata[index] !== undefined) {
          for (
            let pointIndex = 0;
            pointIndex < this.state.JSONdata[index].data.length;
            pointIndex++
          ) {
            let feature = point(this.state.JSONdata[index].data[pointIndex]);
            features.push(feature);
          }
        }
      }

      let collection = featureCollection(features);

      const element = document.createElement("a");
      const file = new Blob([JSON.stringify(collection)], {
        type: "text/plain"
      });
      element.href = URL.createObjectURL(file);
      element.download = "path.json";
      document.body.appendChild(element);
      element.click();
    }
  };

  /**
   * Add resistance layer.
   * @return null
   */
  addResistance = resistance => {
    // TODO : Check that you have no duplicate objects
    let temp = this.state.resistances;
    temp.push(resistance);
    this.setState({ resistances: temp });
  };

  /**
   *  Set optimal path JSON data in with a specific index
   * @return null
   */
  setJSonData = (id, scenarioId, Json, model, color) => {
    let data = this.state.JSONdata;
    data[id] = {
      data: Json,
      model: model,
      color: color,
      errors: [],
      errorsCalculated: false,
      highlight: [],
      id: id,
      scenarioId: scenarioId,
      internalShow: true
    };
    this.setState({ JSONdata: data });
  };

  /**
   *  Set JSONdata state.
   * @return null
   */
  setJSonDataState = newState => {
    this.setState({ JSONdata: newState });
  };

  /**
   * Set optimal path errors to JSON data
   * @param {*} id
   * @param {*} errors
   */
  optimalPathErrors = (id, errors) => {
    let data = this.state.JSONdata;
    if (data[id] !== undefined) {
      data[id].errors.push(errors);
      this.setState({ JSONdata: data });
    }
  };

  /**
   * Set Internal Show value
   * @param {*} id
   * @param {*} visible
   */
  toggleOptimalVisibilityInternalValue = (id, visible) => {
    let data = this.state.JSONdata;
    if (data[id] !== undefined) {
      data[id].internalShow = visible;
      this.setState({ JSONdata: data });
    }
  };

  /**
   * Set optimal path errors calculated to JSON data
   * @param {*} id
   * @param {*} value
   */
  optimalPathErrorsCalculated = (id, value) => {
    let data = this.state.JSONdata;
    if (data[id] !== undefined) {
      data[id].errorsCalculated = value;
      this.setState({ JSONdata: data });
    }
  };

  /**
   *  clear optimal path JSON errors and highlight. Reset all information related errors
   * @param {*} id
   * @return null
   */
  optimalPathErrorsClear = id => {
    let data = this.state.JSONdata;
    if (data[id]) {
      data[id].errors = [];
      data[id].errorsCalculated = false;
      data[id].highlight = [];
      this.setState({ JSONdata: data });
    }
  };

  /**
   * Add optimal path Highlight to JSON data.
   * @param {*} id
   * @param {*} highlight
   */
  optimalPathHighlight = (id, highlight) => {
    let data = this.state.JSONdata;
    if (data[id]) {
      data[id].highlight.push(highlight);
      this.setState({ JSONdata: data });
    }
  };

  /**
   * remove optimal path JSON data.
   * @param {*} id
   */
  unsetJSonData = id => {
    let data = this.state.JSONdata;
    if (data[id]) {
      delete data[id];
      this.setState({ JSONdata: data });
    }
  };

  /**
   * update layers.
   * @param {*} resistance
   */
  removeResistance = resistance => {
    let temp = this.state.resistances;
    let index;
    for (index = 0; index < temp.length; index++) {
      if (temp[index].id === resistance) {
        removeCesiumImageryLayerByName(
          this.state.cesiumViewer,
          resistanceLayerName + temp[index].id
        );
        temp.splice(index, 1);
      }
    }
    this.setState({ resistances: temp });
  };

  /**
   * Add raster layer.
   * @param {*} raster
   */
  addRasterLayer = raster => {
    let currentRasterLayers = this.state.rasterLayers;

    const found = currentRasterLayers.find(element => element.id === raster.id);
    if (!found) {
      // Remove Raster Before Add
      if (currentRasterLayers.length > 0) {
        this.removeRasterLayer(raster.id);
      }

      currentRasterLayers.push(raster);
      this.setState({ rasterLayers: currentRasterLayers });
    }
  };

  /**
   * Remove raster layer.
   * @param {*} raster
   */
  removeRasterLayer = raster => {
    let currentRasterLayers = this.state.rasterLayers;
    let l = currentRasterLayers.length;
    if (l > 0) {
      let index;
      for (index = 0; index < currentRasterLayers.length; index++) {
        if (currentRasterLayers[index].id === raster) {
          // Remove layer from Cesium
          removeCesiumImageryLayerByName(
            this.state.cesiumViewer,
            rasterLayerName + currentRasterLayers[index].id
          );
          // Remove From List
          currentRasterLayers.splice(index, 1);
        }
      }
      this.setState({ rasterLayers: currentRasterLayers });
    }
  };

  /**
   * Add comparison map layer
   * @param {*} comparisonMap
   * @return null
   */
  addComparisonMap = comparisonMap => {
    let temp = this.state.comparisonMaps;
    temp.push(comparisonMap);
    this.setState({ comparisonMaps: temp });
  };

  /**
   * Add corridor layer
   * @param {*} corridor
   * @return null
   */
  addCorridor = corridor => {
    let temp = this.state.corridors;
    temp.push(corridor);
    this.setState({ corridors: temp });
  };

  /**
   * Add geoProcess
   * @param {*} geoproc
   * @return null
   */
  addGeoProcess = geoproc => {
    let temp = this.state.geoprocess;
    temp.push(geoproc);
    this.setState({ geoprocess: temp });
  };

  /**
   * Center map in 3D Mode
   */
  center3DView = () => {
    let projectsVectorSource = getDatasourceLayerByName(
      this.state.cesiumViewer,
      projectVectorName
    );
    this.state.cesiumViewer.flyTo(projectsVectorSource.entities);
  };

  /**
   * Download 3D image
   * @param {*} uri
   * @param {*} name
   */
  downloadURI = (uri, name) => {
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    // mimic click on "download button"
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  /**
   * Update Intermedia Points
   */
  updateScenarioIntermediatepoints = () => {
    let IntermediatePointsSource = getDatasourceLayerByName(
      this.state.cesiumViewer,
      intermediatePointName
    );

    // Create list for back
    let coordinates = [];

    let entities = IntermediatePointsSource.entities.values;
    let l = entities.length;
    let i;
    for (i = 0; i < l; i++) {
      let coord = Ellipsoid.WGS84.cartesianToCartographic(
        entities[i].position._value
      );
      let lon = radianToDegrees(coord.longitude);
      let lat = radianToDegrees(coord.latitude);
      coordinates.push([lon, lat]);
    }

    let Intermediate = {
      type: "MultiPoint",
      coordinates: coordinates
    };
    // updateScenario updates the point list in the AppContext and in backend
    this.context.updateScenario("intermediate_points", Intermediate);
  };

  /**
   * Add an array of intermediate points.
   * @param {*} coord
   */
  addIntermediatePoints = coord => {
    let map = this.state.cesiumViewer;

    let IntermediatePointsSource = getDatasourceLayerByName(
      map,
      intermediatePointName
    );

    let length = IntermediatePointsSource.entities.values.length;
    IntermediatePointsSource.entities.suspendEvents();
    let i;
    for (i = 0; i < coord.length; i++) {
      let point = Cartesian3.fromDegrees(coord[i][0], coord[i][1], 0);

      IntermediatePointsSource.entities.add(
        createIntermediateEntity(point, String(length + i + 1))
      );
    }
    IntermediatePointsSource.entities.resumeEvents();
    this.state.cesiumViewer.scene.requestRender();
  };

  /**
   *  Add intermediate points handler in 3D
   */
  intermediatePoint = () => {
    this.setState({
      drawpoints: true,
      activeInteraction: "drawpoints"
    });
    const map = this.state.cesiumViewer;
    const scene = map.scene;

    let IntermediatePointsSource = getDatasourceLayerByName(
      map,
      intermediatePointName
    );

    const handler = map.screenSpaceEventHandler;

    // Add Blue Marker in cursor movement
    let locationMarker = map.entities.add({
      id: "IntermediateLocationCursor",
      point: {
        pixelSize: 10,
        color: Color.SKYBLUE,
        outlineColor: Color.WHITE,
        heightReference: HeightReference.CLAMP_TO_GROUND,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        outlineWidth: 2
      }
    });

    // Change cursor in movement
    // handler.setInputAction(movement => {
    //   // Change cursor
    //   map.container.style.cursor = "crosshair";
    // }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    // Change cursor, Mouse Move Handler
    handler.setInputAction(movement => {
      let coord = this.getCoordinatesOnMove(movement.endPosition);
      locationMarker.position = coord.point;
      scene.requestRender();
    }, ScreenSpaceEventType.MOUSE_MOVE);

    handler.setInputAction(movement => {
      const coordinates = this.getCoordinatesOnMove(movement.position);

      //World coordinates to geographic coordinates(radian)
      if (coordinates) {
        IntermediatePointsSource.entities.suspendEvents();

        IntermediatePointsSource.entities.add(
          createIntermediateEntity(
            coordinates.point,
            String(IntermediatePointsSource.entities.values.length + 1)
          )
        );

        this.updateScenarioIntermediatepoints();
        IntermediatePointsSource.entities.resumeEvents();
      }

      scene.requestRender();
    }, ScreenSpaceEventType.LEFT_CLICK);
  };

  /**
   * Remove intermediate points action
   */
  resetMapInteractions = () => {
    this.setState({ drawpoints: false, activeInteraction: "" });
    let map = this.state.cesiumViewer;
    let scene = map.scene;
    let handler = map.screenSpaceEventHandler;
    handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
    //map.container.style.cursor = "default";
    map.entities.removeById("IntermediateLocationCursor");
    scene.requestRender();
  };

  /**
   * Utility method to convert from cartesian to cartographic,
   * convert rad to deg, and eventually get lon and lat.
   */
  getCoordinatesOnMove = movement => {
    const map = this.state.cesiumViewer;
    const camera = map.camera;
    const scene = map.scene;
    const ray = camera.getPickRay(movement);

    if (ray) {
      const position = scene.globe.pick(ray, map.scene);
      if (position) {
        const cartographic = Ellipsoid.WGS84.cartesianToCartographic(position);

        if (cartographic) {
          // Elevation
          const height = scene.globe.getHeight(cartographic);

          return {
            lon: radianToDegrees(cartographic.longitude),
            lat: radianToDegrees(cartographic.latitude),
            point: Cartesian3.fromRadians(
              cartographic.longitude,
              cartographic.latitude,
              height
            )
          };
        }
      }
    }
    return null;
  };

  /**
   * Method to add a start point for the current scenario or project. To do it, we
   * use the setInputAction method in Cesium screenSpaceEventHandler to capture
   * the mouse click when moving around the globe and get the data from this
   * point converted and stored in context to be used by CesiumGLobe on next render.
   * The parameter { origin } is used to indicate if we are creating a point
   * for the current scenario or for the project.
   */
  addStartPoint = origin => {
    this.setState({ drawpoints: true, activeInteraction: "start" });
    const map = this.state.cesiumViewer;
    const scene = map.scene;

    const projectVector = getDatasourceLayerByName(map, projectVectorName);

    const handler = map.screenSpaceEventHandler;

    // Add Blue Marker in cursor movement
    let locationMarker = map.entities.add({
      id: "IntermediateLocationCursor",
      point: {
        pixelSize: 10,
        color: Color.SKYBLUE,
        outlineColor: Color.WHITE,
        heightReference: HeightReference.CLAMP_TO_GROUND,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        outlineWidth: 2
      }
    });

    // Change cursor, Mouse Move Handler
    handler.setInputAction(movement => {
      const coordinates = this.getCoordinatesOnMove(movement.endPosition);
      locationMarker.position = coordinates?.point;
      scene.requestRender();
    }, ScreenSpaceEventType.MOUSE_MOVE);

    // Handle Mouse Left Click
    handler.setInputAction(movement => {
      //World coordinates to geographic coordinates(radian)
      const coordinates = this.getCoordinatesOnMove(movement.position);

      if (coordinates) {
        projectVector.entities.suspendEvents();
        let coord = [coordinates.lon, coordinates.lat];
        // If the user add a start point, we need to add it to the project vector
        // We need update the project start point to gost style

        changeFlagAlphaById(projectVector, startPointProjectName, 0.4);
        // Draw start scenario point
        this.drawProjectPoints(projectVector, coord, startPointScenarioName);

        // Update start point in context
        const startPoint = {
          type: "Point",
          coordinates: coord
        };

        if (origin === "project") {
          this.context.updateProject("start_point", startPoint);
        } else if (origin === "scenario") {
          this.context.updateScenario("start_point", startPoint);
        }

        projectVector.entities.resumeEvents();
      }
      scene.requestRender();
    }, ScreenSpaceEventType.LEFT_CLICK);
  };

  /**
   * Method to add a end point for the current scenario or project. To do it, we
   * use the setInputAction method in Cesium screenSpaceEventHandler to capture
   * the mouse click when moving around the globe and get the data from this
   * point converted and stored in context to be used by CesiumGLobe on next render.
   * The parameter { origin } is used to indicate if we are creating a point
   * for the current scenario or for the project.
   */
  addEndPoint = origin => {
    this.setState({ drawpoints: true, activeInteraction: "end" });
    const map = this.state.cesiumViewer;
    const scene = map.scene;

    const projectVector = getDatasourceLayerByName(map, projectVectorName);

    const handler = map.screenSpaceEventHandler;

    // Add Blue Marker in cursor movement
    let locationMarker = map.entities.add({
      id: "IntermediateLocationCursor",
      point: {
        pixelSize: 10,
        color: Color.SKYBLUE,
        outlineColor: Color.WHITE,
        heightReference: HeightReference.CLAMP_TO_GROUND,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        outlineWidth: 2
      }
    });

    // Change cursor, Mouse Move Handler
    handler.setInputAction(movement => {
      const coordinates = this.getCoordinatesOnMove(movement.endPosition);
      locationMarker.position = coordinates.point;
      scene.requestRender();
    }, ScreenSpaceEventType.MOUSE_MOVE);

    handler.setInputAction(movement => {
      //World coordinates to geographic coordinates(radian)
      const coordinates = this.getCoordinatesOnMove(movement.position);

      if (coordinates) {
        projectVector.entities.suspendEvents();

        let coord = [coordinates.lon, coordinates.lat];
        // Make point ghost if exist
        changeFlagAlphaById(projectVector, endPointProjectName, 0.4);
        // Draw end scenario point
        this.drawProjectPoints(
          projectVector,
          coord,
          endPointScenarioName,
          false
        );

        // Update end point in context
        const endPoint = {
          type: "Point",
          coordinates: coord
        };

        if (origin === "project") {
          this.context.updateProject("end_point", endPoint);
        } else if (origin === "scenario") {
          this.context.updateScenario("end_point", endPoint);
        }

        projectVector.entities.resumeEvents();
      }
      scene.requestRender();
    }, ScreenSpaceEventType.LEFT_CLICK);
  };

  /**
   * Toggle show and hide pylons models in 3D
   */
  togglePylons3D = isShown => {
    // Disable Pylons toolbar if is opened
    if (this.state.toggle3DToolbar.includes("pylons_3d_tools")) {
      this.set3DToolbar("pylons_3d_tools");
    }
    // Toggle pylons and set default height
    let pathSource = getDatasourceLayerByName(
      this.state.cesiumViewer,
      optimalPathVectorName
    );
    pathSource.entities.suspendEvents();
    let JSONdata = this.state.JSONdata;
    let entities = pathSource.entities.values;
    for (const element of entities) {
      let showPylons = isShown;

      // Only change the visible paths
      // if (JSONdata[element.group].internalShow) {
      // Towers
      if (element.name === towerName) {
        element.model.show = showPylons;
      } else if (element.name === lineName) {
        // Wires
        let currentPositions = showPylons
          ? element.internalPosition
          : element.constantHeightPosition;

        let clampToGround = showPylons ? false : true;

        // we only want show one line
        if (showPylons) {
          element.show = true;
        } else {
          let isOne = element.wireIndex === 0 ? true : false;
          element.show = isOne;
        }
        element.polyline.positions.setValue(currentPositions);
        element.polyline.clampToGround = clampToGround;
      }
      // }
    }
    pathSource.entities.resumeEvents();
    this.state.cesiumViewer.scene.requestRender();
  };

  /**
   * Toggle show and hide labels models in 3D
   */
  toggleLabels3D = isShown => {
    // TODO not implemented
    return;
  };

  /**
   * Toggle show and hide routing width in 3D
   */
  toggleShowRoutingWidth3D = isShown => {
    // TODO not implemented
    return;
  };

  /**
   * ScreenShot 3D Cesium
   */
  screenshot3D = (projectName, userName) => {
    //show wait message
    this.context.messages.showWait();
    // configure settings
    let targetResolutionScale = 2.0; // for screenshots with higher resolution set to 2.0 or even 3.0
    // Cesium Map
    let map = this.state.cesiumViewer;
    // define callback functions
    let prepareScreenshot = () => {
      map.resolutionScale = targetResolutionScale;
      // take snapshot after defined timeout to allow scene update (ie. loading data)
      setTimeout(() => {
        map.scene.postRender.addEventListener(takeScreenshot);
        // Reset to default resolution
        map.resolutionScale = 1.0;
        map.scene.requestRender();
      }, 3000);
    };

    let takeScreenshot = async () => {
      map.scene.postRender.removeEventListener(takeScreenshot);

      const watermarkedScreenshotBlob = await generateMapScreenshot({
        mapCanvas: map.scene.canvas,
        projectName,
        userName
      });

      saveBlobAsImage(
        watermarkedScreenshotBlob,
        `${projectName}_3D_screenshot.png`
      );
      // hide wait message
      this.context.messages.hideWait();
    };
    return prepareScreenshot();
  };

  /**
   * Show raster geoprocess result in Cesium Globe
   */
  viewGeoprocessRaster = params => {
    let layer = new SingleTileImageryProvider({
      url: params.url,
      rectangle: Rectangle.fromDegrees(...params.imageExtent)
    });
    let imgLayer = this.state.cesiumViewer.imageryLayers.addImageryProvider(
      layer,
      this.state.cesiumViewer.imageryLayers.length
    );
    imgLayer.alpha = 1;
    imgLayer.name = params.id + "geoprocess";
  };

  /**
   *  Update camera Mode (pedestrian or globe)    .
   * @return null
   */
  setCameraMode = mode => {
    this.setState({ cameraMode: mode });
  };

  /**
   * Signal Cesium Map Loaded
   */
  mapLoaded = viewer => {
    this.setState({ cesiumViewer: viewer });
    // There is a bug that sometimes does not
    // load the terrain well and the globe becomes invisible.This is solved by making a swicth of the terrain
    // github.com/CesiumGS/cesium/issues/8322
    // this.set3DTerrain();
    //
    this.restoreMapHandlers(viewer.screenSpaceEventHandler, false);
  };

  /**
   * Create correct Order in ImageryLayers
   */
  orderImageryLayers = () => {
    // RM Always minus Z index value
    // Raise to top corridors
    raiseToTopImageryLayerByName(this.state.cesiumViewer, corridorLayerName);
  };

  /**
   * Add Default 3D Terrain
   */

  set3DTerrain = (assetNumber = 1) => {
    if (
      this.state.cesiumViewer.terrainProvider instanceof
        EllipsoidTerrainProvider ||
      assetNumber !== this.state.terrainAsset
    ) {
      let terrainProvider = new CesiumTerrainProvider({
        url: IonResource.fromAssetId(assetNumber)
      });

      let updatedViewer = this.state.cesiumViewer;
      updatedViewer.terrainProvider = terrainProvider;
      this.setState({ cesiumViewer: updatedViewer });

      // Repaint Cables if change the Terrain
      this.refreshWireSource(false);
      // Set terrain Asset Number
      this.setState({ terrainAsset: assetNumber });
    }
  };

  /**
   * Switch 3D Cesium Terrain
   */
  switchTerrain = () => {
    let terrainProvider;
    if (
      this.state.cesiumViewer.terrainProvider instanceof
      EllipsoidTerrainProvider
    ) {
      terrainProvider = new CesiumTerrainProvider({
        url: IonResource.fromAssetId(this.state.terrainAsset)
      });
    } else {
      terrainProvider = new EllipsoidTerrainProvider({});
    }
    // Recreate Only the lines
    let updatedViewer = this.state.cesiumViewer;
    updatedViewer.terrainProvider = terrainProvider;
    this.setState({ cesiumViewer: updatedViewer });

    // Refresh Optimal path
    this.refreshWireSource(false);
    // Close the cable errors if is opened
    if (this.state.openCableErrors) {
      this.toggleCableErrorsDialog(false);
    }

    // TODO : Check buildings
    // When we change the terrain need clamp to new terrain the buildings
    // This is not yet possible
    // let primitives = this.state.cesiumViewer.scene.primitives._primitives;
    // for (const prop in primitives) {
    //   if (primitives[prop] instanceof Cesium.Cesium3DTileset) {
    //     if (primitives[prop].name === "OSMBuildings") {
    //       this.updateTileset(primitives[prop]);
    //     }
    //   }
    // }
  };

  /**
   * Open or close the cable errors dialog
   * @param {*} value
   */
  toggleCableErrorsDialog = value => {
    this.setState({ openCableErrors: value });
  };

  /**
   * Switch 3D Cesium Underground visualization
   */
  switchUnderground = (show, area) => {
    let scene = this.state.cesiumViewer.scene;
    setCesiumBackgroundTransparency(this.state.cesiumViewer, show);
    scene.requestRender();
  };

  /**
   * Function to delete old Cesium handlers
   */
  restoreMapHandlers = (
    handler = this.state.cesiumViewer.screenSpaceEventHandler,
    removeOld = true
  ) => {
    if (removeOld) {
      handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
      handler.removeInputAction(ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      handler.removeInputAction(ScreenSpaceEventType.RIGHT_CLICK);
      handler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
      handler.removeInputAction(ScreenSpaceEventType.LEFT_DOWN);
      handler.removeInputAction(ScreenSpaceEventType.LEFT_UP);
    }
    // Remove Tooltips
    getCesiumTooltip();
  };

  /**
   * Get the midpoint between start and end points
   * Return Cesium Cartographic
   */
  getStartEndMidpoint = () => {
    let map = this.state.cesiumViewer;
    let ProjectsVectorSource = getDatasourceLayerByName(map, projectVectorName);

    let entities = ProjectsVectorSource.entities.values;
    let points = [];
    try {
      let i;
      for (i = 1; i < 3; i++) {
        let coord = Ellipsoid.WGS84.cartesianToCartographic(
          entities[i].position._value
        );
        let lon = radianToDegrees(coord.longitude);
        let lat = radianToDegrees(coord.latitude);

        points.push(point([lon, lat]));
      }

      let calculatedMidpoint = getCoord(midpoint(points[0], points[1]));
      calculatedMidpoint = new Cartographic(
        degreesToRadians(calculatedMidpoint[0]),
        degreesToRadians(calculatedMidpoint[1]),
        0
      );
      return calculatedMidpoint;
    } catch {
      return new Cartographic(degreesToRadians(0), degreesToRadians(90), 0);
    }
  };

  /**
   * Toggle OSM Buildings visibility
   * Add/Remove 3D Tiles
   */
  toggle3DBuildings = show => {
    if (!show) {
      // Search if OSM Buildings is loaded
      let [found, primitive] = find3DTilesByName(
        this.state.cesiumViewer,
        "OSMBuildings"
      );
      if (found) {
        this.state.cesiumViewer.scene.primitives.remove(primitive);
        this.state.cesiumViewer.scene.requestRender();
        return;
      }
    } else {
      // Load 3D tiles
      let buildingsTileset =
        this.state.cesiumViewer.scene.primitives.add(createOsmBuildings());

      buildingsTileset.readyPromise
        .then(() => {
          buildingsTileset.name = "OSMBuildings";
          // Add terrain if it's not loaded. The OSM Buildings only work well with 3D Terrain
          this.set3DTerrain(this.state.terrainAsset);
          this.state.cesiumViewer.scene.requestRender();
        })
        .otherwise(() => {
          this.props.enqueueSnackbar(
            this.props.t("error.ErrorloadingBuildings"),
            {
              variant: "error"
            }
          );
        });
    }
  };

  /**
   * Add a Cesium Ion asset to the Globe
   */
  add3DAsset = (token, assetID, type, clampToGround) => {
    Ion.defaultAccessToken = token;

    switch (type) {
      case "3DTilesObjs":
      case "3DTilesPts":
      case "3DTilesPtsClass":
        // Load 3D tiles
        let options = {
          url: IonResource.fromAssetId(assetID)
        };
        let tileset3D = new Cesium3DTileset(options);

        if (type === "3DTilesPts" || type === "3DTilesPtsClass") {
          tileset3D.pointCloudShading.attenuation = true;
          tileset3D.pointCloudShading.maximumAttenuation = 8.0;
        }
        let tileset = this.state.cesiumViewer.scene.primitives.add(tileset3D);

        tileset.readyPromise
          .then(() => {
            this.props.enqueueSnackbar(this.props.t("Add3DAsset.AddedOK"), {
              variant: "success"
            });
            this.state.cesiumViewer.zoomTo(tileset);

            let style = {};
            if (type === "3DTilesPtsClass")
              /* eslint-disable no-template-curly-in-string */
              style = {
                pointSize: 3.0,
                color: {
                  conditions: [
                    ["${Classification} === 1", "color('cornsilk')"], // unassigned
                    ["${Classification} === 2", "color('khaki')"], // ground
                    ["${Classification} === 3", "color('greenyellow')"], // low vegetation
                    ["${Classification} === 4", "color('green')"], // medium vegetation
                    ["${Classification} === 5", "color('darkgreen')"], // high vegetation
                    ["${Classification} === 6", "color('bisque')"], // building
                    ["${Classification} === 9", "color('aqua')"], // water
                    ["${Classification} === 10", "color('darkgoldenrod')"], // railway
                    ["${Classification} === 11", "color('lightgrey')"], // road
                    ["${Classification} === 12", "color('crimson')"], // Terna: base support points
                    ["${Classification} === 13", "color('dodgerblue')"], // wires: conductor
                    ["${Classification} === 14", "color('darkturquoise')"], // Terna: wires: phase
                    ["${Classification} === 15", "color('darkblue')"], // towers
                    ["${Classification} === 16", "color('darkviolet')"], // Terna: insulator
                    // additional Terna values
                    ["${Classification} === 17", "color('lightslategrey')"], // highway overpass
                    ["${Classification} === 19", "color('darkkhaki')"], // railway contact line
                    ["${Classification} === 20", "color('orange')"], // gas pipeline
                    ["${Classification} === 21", "color('violet')"], // phone line
                    ["${Classification} === 22", "color('deepskyblue')"], // conductor < 1000 V
                    ["${Classification} === 23", "color('mediumblue')"], // conductor 1000 - 30 kV
                    ["${Classification} === 24", "color('cornflowerblue')"], // conductor 30 kV - 150 kV
                    ["${Classification} === 25", "color('indigo')"], // conductor 220 kV
                    ["${Classification} === 26", "color('navy')"], // conductor 380 kV
                    ["${Classification} === 27", "color('lightblue')"], // guard ropes (parallel lines) - firebrick
                    ["${Classification} === 28", "color('darkgrey')"], // line supports (towers) - lightcoral
                    ["true", "color('white')"]
                  ]
                }
              };
            else if (type === "3DTilesPts") style = { pointSize: 3.0 };

            tileset.style = new Cesium3DTileStyle(style);
          })
          .otherwise(error => {
            this.props.enqueueSnackbar(
              this.props.t("Add3DAsset.ErrorLoading") + error,
              {
                variant: "error"
              }
            );
          });
        break;

      case "GeoJSON":
      case "KML":
        IonResource.fromAssetId(assetID)
          .then(resource => {
            let options = {
              clampToGround: clampToGround
            };
            let loadPromise;
            if (type === "GeoJSON")
              loadPromise = GeoJsonDataSource.load(resource, options);
            else {
              options.camera = this.state.cesiumViewer.scene.camera;
              options.canvas = this.state.cesiumViewer.scene.canvas;
              loadPromise = KmlDataSource.load(resource, options);
            }

            loadPromise
              .then(dataSource => {
                // Add the new data as entities to the viewer
                this.state.cesiumViewer.dataSources.add(dataSource);
                this.props.enqueueSnackbar(this.props.t("Add3DAsset.AddedOK"), {
                  variant: "success"
                });
                this.state.cesiumViewer.zoomTo(dataSource);
              })
              .otherwise(error => {
                this.props.enqueueSnackbar(
                  this.props.t("Add3DAsset.ErrorLoading") + error,
                  {
                    variant: "error"
                  }
                );
              });
          })
          .otherwise(error => {
            this.props.enqueueSnackbar(
              this.props.t("Add3DAsset.ErrorLoading") + error,
              {
                variant: "error"
              }
            );
          });
        break;

      case "Terrain":
        this.set3DTerrain(assetID);
        this.props.enqueueSnackbar(this.props.t("Add3DAsset.TerrainOK"), {
          variant: "success"
        });
        break;
      default:
        break;
    }
  };

  /**
   * Function to restore the project points for the scenario.
   * It sets the scenario points to null, the application will take the project
   * start/end points as the valid ones.
   * @param {string} point
   */
  restoreProjectPoints = () => {
    this.resetMapInteractions();
    // Removw the scenario points
    let map = this.state.cesiumViewer;
    let projectVector = getDatasourceLayerByName(map, projectVectorName);

    //removeDatasourceEntityById(projectVector, startPointScenarioName);
    //removeDatasourceEntityById(projectVector, endPointScenarioName);

    this.state.cesiumViewer.scene.requestRender();
  };

  /**
   * Change the path color
   * Color selected by the user
   * @param {Number} id Optimal path id
   * @param {String} color Hexadecimal color
   * @param {Boolean} visible Indicates if the path is visible or not
   */
  setColorAndPathMetadata = (id, color) => {
    let optimalPathVectorSource = getDatasourceLayerByName(
      this.state.cesiumViewer,
      optimalPathVectorName
    );
    let entities = optimalPathVectorSource.entities;
    let values = this.state.JSONdata[id];
    if (entities.values.length === 0 || values === undefined) {
      return;
    }

    changePolylineMaterialByName(entities, id, color);

    let points = values.data;
    let model = values.model;
    let scenarioId = values.scenarioId;

    this.setJSonData(id, scenarioId, points, model, color);

    this.state.cesiumViewer.scene.requestRender();
  };

  /**
   * Show comparison map in Cesium Globe
   */

  viewComparisonMap = () => {
    // Get Comparison Map palette
    getPalette("ana-op2-full-3zones")
      .then(palette => {
        let i;
        for (i = 0; i < this.state.comparisonMaps.length; i++) {
          let d = this.state.comparisonMaps[i];
          let layerName = comparisonMapLayerName + d.id;
          if (d.tms) {
            // Load Tile Map Service
            const payload = {
              layerId: d.id,
              viewer: this.state.cesiumViewer,
              gradient: palette,
              tmsUrl: d.tms,
              name: layerName,
              extent: d.extent,
              color: null,
              source: "comparisonMap"
            };
            loadTMSWithColor(payload);
          } else {
            this.addImageryLayer(layerName, d);
          }
        }
      })
      .catch(e =>
        this.props.enqueueSnackbar(this.props.t("error.palette"), {
          variant: "error"
        })
      );
  };

  /**
   * Show corridor map in Cesium Globe
   */

  viewCorridor = () => {
    // Get Corridor palette
    getPalette("Corridor")
      .then(palette => {
        let i;
        for (i = 0; i < this.state.corridors.length; i++) {
          let d = this.state.corridors[i];
          let layerName = corridorLayerName + d.id;
          if (d.tms) {
            // Load Tile Map Service
            const payload = {
              layerId: d.id,
              viewer: this.state.cesiumViewer,
              gradient: palette,
              tmsUrl: d.tms,
              name: layerName,
              extent: d.extent,
              color: null,
              source: "corridor"
            };
            loadTMSWithColor(payload);
          } else {
            this.addImageryLayer(layerName, d);
          }
        }
      })
      .catch(e =>
        this.props.enqueueSnackbar(this.props.t("error.palette"), {
          variant: "error"
        })
      );
  };

  /**
   * Update Colorize Image Layer
   * @param {*} layerId
   * @param {*} color
   */
  updateColoredImageryLayer = (layerId, color) => {
    let layer = getImageryLayersByName(
      this.state.cesiumViewer,
      rasterLayerName + layerId
    );
    // Update raster layer color
    if (layer && layer.imageryProvider.ready) {
      layer.imageryProvider.color = rgbaFromString(color);
      this.state.cesiumViewer.scene.requestRender();
    }
  };

  /**
   * Add Imagery Layer to Cesium
   * @param {*} name
   * @param {*} data
   */
  addImageryLayer = (name, data) => {
    let layer = new SingleTileImageryProvider({
      url: data.image,
      rectangle: Rectangle.fromDegrees(...data.extent)
    });
    let imgLayer = this.state.cesiumViewer.imageryLayers.addImageryProvider(
      layer,
      this.state.cesiumViewer.imageryLayers.length
    );
    imgLayer.alpha = data.alpha;
    imgLayer.name = name;
  };

  /**
   * Show/Hide raster layer in Cesium Globe
   */

  viewRasterLayer = () => {
    let i;
    let rasterLayers = this.state.rasterLayers;
    let l = rasterLayers.length;
    for (i = 0; i < l; i++) {
      let rasterLayer = rasterLayers[i];
      // Prevent add duplicated layers
      let layer = getImageryLayersByName(
        this.state.cesiumViewer,
        rasterLayerName + rasterLayer.id
      );
      if (!layer) {
        if (rasterLayer.tms) {
          // Load Tile Map Service
          const payload = {
            layerId: rasterLayer.id,
            viewer: this.state.cesiumViewer,
            gradient: null,
            tmsUrl: rasterLayer.tms,
            name: rasterLayerName + rasterLayer.id,
            extent: rasterLayer.extent,
            color: rgbaFromString(rasterLayer.color),
            source: "rasterLayer"
          };
          loadTMSWithColor(payload);
        } else {
          addColoredImageryLayer(
            this.state.cesiumViewer,
            rasterLayerName,
            rasterLayer,
            rasterLayer.color
          );
        }
      }
    }
  };

  /**
   * Show resistance map in Cesium Globe
   */
  viewResistance = () => {
    getPalette("RM_YBR")
      .then(palette => {
        let i;
        let resistances = this.state.resistances;
        let l = resistances.length;
        for (i = 0; i < l; i++) {
          let d = resistances[i];
          let layerName = resistanceLayerName + d.id;
          if (d.tms) {
            // Load Tile Map Service
            const payload = {
              layerId: d.id,
              viewer: this.state.cesiumViewer,
              gradient: palette,
              tmsUrl: d.tms,
              name: layerName,
              extent: d.extent,
              color: null,
              source: "resistanceMap"
            };
            loadTMSWithColor(payload);
          } else {
            this.addImageryLayer(layerName, d);
          }
        }
      })
      .catch(e =>
        this.props.enqueueSnackbar(this.props.t("error.palette"), {
          variant: "error"
        })
      );
  };

  setStreetViewObject = streetViewObject => {
    this.streetViewObject = streetViewObject;
  };

  getStreetViewObject = () => {
    return this.streetViewObject;
  };

  /**
   * Toggle Street View Tool
   */
  streetView = () => {
    // Set activated or dactivated streetView and unselect measure and pylons
    this.setState(
      {
        isStreeView: !this.state.isStreeView,
        toggle3DToolbar: []
      },
      () => {
        if (this.state.isStreeView) {
          // Add Help Tooltip
          let tooltip = getCesiumTooltip();
          let handler = this.state.cesiumViewer.screenSpaceEventHandler;

          // Street view move Event
          handler.setInputAction(movement => {
            let cartographic = getCartographic(
              this.state.cesiumViewer,
              movement.endPosition
            );

            if (cartographic) {
              tooltip.style.left = movement.endPosition.x + 10 + "px";
              tooltip.style.top = movement.endPosition.y + 20 + "px";
              tooltip.style.display = "block";

              let helpMsg = this.props.t("StreetView.start");
              if (this.streetViewObject) {
                helpMsg = this.props.t("StreetView.continue");
              }
              tooltip.innerHTML = helpMsg;
            }
          }, ScreenSpaceEventType.MOUSE_MOVE);

          // Street view Click Event
          handler.setInputAction(click => {
            if (this.state.isStreeView) {
              this.StreeViewClickCallback(click);
              return;
            }
            this.cleanSVPoint();
          }, ScreenSpaceEventType.LEFT_CLICK);
        } else {
          this.cleanSVPoint(true);
        }
      }
    );
  };

  /**
   * Update Street View Marker
   * @param {*} heading
   */
  updateSVPov = heading => {
    let currentPoint = this.state.cesiumViewer.entities.getById("SVPoint");

    if (currentPoint) {
      let pitch = 0;
      let roll = 0;
      let currentPosition = currentPoint.position.getValue();
      var hpr = new HeadingPitchRoll(heading * (Math.PI / 180), pitch, roll);

      var orientation = Transforms.headingPitchRollQuaternion(
        currentPosition,
        hpr
      );

      currentPoint.orientation = orientation;
    }
    this.state.cesiumViewer.scene.requestRender();
  };

  /**
   * Clean Stree View Source
   */
  cleanSVPoint = (close = false) => {
    let currentPoint = this.state.cesiumViewer.entities.getById("SVPoint");
    if (currentPoint) {
      this.setState({ svCoord: [] });
      removeMapEntityById(this.state.cesiumViewer, "SVPoint");
    }
    if (close) {
      this.setState({ isStreeView: false });
    }
    this.restoreMapHandlers(this.state.cesiumViewer.screenSpaceEventHandler);
    // Remove tooltip
    getCesiumTooltip();
    // Remove StreetView Object
    this.setStreetViewObject(null);
  };

  /**
   * Update Point from SV Viewer
   * @param {*} lng
   * @param {*} lat
   */
  updateSVPoint = (lng, lat) => {
    let currentPoint = this.state.cesiumViewer.entities.getById("SVPoint");
    if (currentPoint) {
      let newPos = new Cartesian3.fromDegrees(lng, lat);
      currentPoint.position = newPos;
    }
    this.state.cesiumViewer.scene.requestRender();
  };

  /**
   * Cesium Map Click Event
   * @param {*} evt
   */
  StreeViewClickCallback = evt => {
    // Clean old point
    removeMapEntityById(this.state.cesiumViewer, "SVPoint");
    const coordinates = this.getCoordinatesOnMove(evt.position);
    if (coordinates) {
      // Draw Point
      let lon = coordinates.lon;
      let lat = coordinates.lat;
      drawSVPoint(this.state.cesiumViewer, [lon, lat], "SVPoint");

      this.setState({ svCoord: [Number(lat), Number(lon)] });
      this.state.cesiumViewer.scene.requestRender();
    }
  };

  /**
   * Draw start/end flags
   * @param {*} projectVector
   * @param {*} coordinates
   * @param {*} id
   * @param {*} isStart
   * @returns
   */
  drawProjectPoints = (projectVector, coordinates, id, isStart = true) => {
    if (!coordinates) return;

    let flagEntity = projectVector.entities.getById(id);

    // Update the current point if exist
    if (flagEntity) {
      flagEntity.position = Cartesian3.fromDegrees(
        coordinates[0],
        coordinates[1]
      );
    }

    // Create new flag if not exist
    else {
      let name = isStart ? "common.start" : "common.end";
      let color = isStart
        ? getColorByString("#0fff7b")
        : getColorByString("#0fa7ff");
      projectVector.entities.add(
        createFlagEntity(
          id,
          coordinates,
          color,
          this.props.t(name).toUpperCase()
        )
      );
    }
  };

  /**
   * Render
   * @returns
   */
  render() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Map3DContext.Provider
          value={{
            state: this.state,
            center3D: this.center3DView, // Function to center the view on the area
            screenshot3D: this.screenshot3D, // Function to make screenshot
            togglePylons3D: this.togglePylons3D, // Toggle show/hide pylons models
            toggleLabels3D: this.toggleLabels3D, // Toggle show/hide pylons models
            toggleShowRoutingWidth3D: this.toggleShowRoutingWidth3d, // Toggle show/hide pylons models
            setColorAndPathMetadata: this.setColorAndPathMetadata,
            updateColoredImageryLayer: this.updateColoredImageryLayer,
            showResistance: this.viewResistance,
            viewGeoprocessRaster: this.viewGeoprocessRaster, //Function to view raster geoproprocess result
            showRasterLayer: this.viewRasterLayer,
            showComparisonMap: this.viewComparisonMap,
            showCorridor: this.viewCorridor,
            setCameraMode: this.setCameraMode,
            cameraMode: this.state.cameraMode,
            viewer: this.state.cesiumViewer, // Main map object // as global to get access anywhere ,
            mapLoaded: this.mapLoaded,
            removeResistance: this.removeResistance,
            addResistance: this.addResistance,
            addRasterLayer: this.addRasterLayer,
            removeRasterLayer: this.removeRasterLayer,
            addComparisonMap: this.addComparisonMap,
            removeComparisonMap: this.removeComparisonMap,
            addCorridor: this.addCorridor,
            removeCorridor: this.removeCorridor,
            switchTerrain: this.switchTerrain,
            switchUnderground: this.switchUnderground,
            setJSonData: this.setJSonData,
            setJSonDataState: this.setJSonDataState,
            unsetJSonData: this.unsetJSonData,
            set3DToolbar: this.set3DToolbar,
            intermediatePoint: this.intermediatePoint, // intermediate point drawing method
            addStartPoint: this.addStartPoint,
            addEndPoint: this.addEndPoint,
            resetMapInteractions: this.resetMapInteractions, // function to stop feature interactions
            activeInteraction: this.state.activeInteraction, // variable to check active states in draw
            updateContextScenario: this.updateContextScenario, // Function to update intermediate points context
            addIntermediatePoints: this.addIntermediatePoints,
            restoreMapHandlers: this.restoreMapHandlers,
            getStartEndMidpoint: this.getStartEndMidpoint,
            updateScenarioIntermediatepoints:
              this.updateScenarioIntermediatepoints,
            refreshWireSource: this.refreshWireSource,
            toggle3DBuildings: this.toggle3DBuildings,
            add3DAsset: this.add3DAsset,
            restoreProjectPoints: this.restoreProjectPoints,
            optimalPathHighlight: this.optimalPathHighlight,
            exportOptimalPath: this.exportOptimalPath,
            optimalPathErrorsClear: this.optimalPathErrorsClear,
            optimalPathErrors: this.optimalPathErrors,
            optimalPathErrorsCalculated: this.optimalPathErrorsCalculated,
            toggleOptimalVisibilityInternalValue:
              this.toggleOptimalVisibilityInternalValue,
            pointFunctions: {},
            setStreetViewObject: this.setStreetViewObject,
            getStreetViewObject: this.getStreetViewObject,
            streetView: this.streetView,
            cleanSVPoint: this.cleanSVPoint,
            updateSVPoint: this.updateSVPoint,
            updateSVPov: this.updateSVPov,
            toggleCableErrorsDialog: this.toggleCableErrorsDialog,
            set3DTerrain: this.set3DTerrain
          }}
        >
          {this.props.children}

          <CesiumGlobeReborn onViewerReady={() => {}} />
        </Map3DContext.Provider>
      </div>
    );
  }
}

let Map3DConsumer = Map3DContext.Consumer;
export { Map3DConsumer };
export default withTranslation()(withSnackbar(Map3DProvider));
Map3DProvider.contextType = AppContext;
