import React, { Component } from "react";
import { withSnackbar } from "notistack";

import { AppContext } from "AppProvider";

import OlMap from "ol/Map";
import OlView from "ol/View";
import DoubleClickZoom from "ol/interaction/DoubleClickZoom";
import GeoJSON from "ol/format/GeoJSON.js";
import OlLayerGroup from "ol/layer/Group";
import OlLayerImage from "ol/layer/Image";
import Collection from "ol/Collection";
import { Draw, Modify } from "ol/interaction.js";
import { createRegularPolygon, createBox } from "ol/interaction/Draw.js";
import OlLayerVector from "ol/layer/Vector";
import OlVectorSource from "ol/source/Vector";
import Cluster from "ol/source/Cluster.js";
import { transformExtent, transform } from "ol/proj";
import Feature from "ol/Feature";
import { defaults as defaultControls } from "ol/control.js";
import { Point } from "ol/geom";
import Overlay from "ol/Overlay";
import { defaults as defaultInteractions } from "ol/interaction";

import { withTranslation } from "react-i18next";
import {
  reprojectPointAsCoords,
  reprojectFeature,
  unprojectFeature
} from "components/Dashboard2D/SrcTransform";

import {
  toggleActiveInteraction,
  resetMapInteractions,
  removeActiveDrawInteraction,
  getDrawInteraction,
  olCircleInMeters,
  getMainLineStringFromPathLayer
} from "components/Dashboard2D/OLLayerUtils";

import { OLMapStyles } from "components/Dashboard2D/OLMapStyles";
import {
  SCENARIO_SETTINGS_TAB,
  EPSG_4326,
  EPSG_3857,
  DEFAULT_COLOR,
  EDITOR_MODES
} from "assets/global";
import { getCenter } from "ol/extent";
import { generateMapScreenshot, saveBlobAsImage } from "./utils/WatermarkTools";
import { normalizeLongitude } from "utils/OLMapUtils";

const MapStylesObject = new OLMapStyles();
const MapContext = React.createContext();
export { MapContext };

/**
 * Component for the 2d OpenLayers map
 */

const defaultCenter = [-20, 50];
const defaultZoom = 2;

/**
 * Default values for some OpenLayers settings
 */
const maxZoomLevel = 19;
const clusterSourcesDistancePx = 50;

// StreetView ToolTip
let SVTooltipElement;
let SVTooltip;

class MapProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentProject: -1,
      drawpoints: false, // Boolean - intermediate points draw mode active
      isStreeView: false,
      svCoord: []
    };

    this.areaSource = new OlVectorSource(); // Vector source for the project area
    this.startPointSource = new OlVectorSource(); // Start point source
    this.endPointSource = new OlVectorSource(); // End point source
    this.intermediatePointSource = new OlVectorSource(); // Intermediate points source
    this.auxIPSource = new OlVectorSource(); // Intermediate function point vector source
    this.pathSource = new OlVectorSource(); //Optimal path
    this.SVPointSource = new OlVectorSource(); // Street View Points Source
    this.highlightedPointsSource = new OlVectorSource(); // For the selected pylons or other highlighted features.
    this.commentsSource = new OlVectorSource(); // Threads/Comments Source
    this.commentsClusterSource = new Cluster({
      distance: clusterSourcesDistancePx,
      minDistance: 30, //this goes side by side with the styling Max circle radius
      source: this.commentsSource
    });

    this.vectorOfLayers = new Collection(); // contains the project buffered layers
    this.vectorOfLayers.set("name", "vectorLayerCollection");
    this.rasterLayersCollection = new Collection(); // contains the project raster layers
    this.rasterLayersCollection.set("name", "rasterLayerCollection");

    this.resistanceCollection = new Collection(); // contains the scenarios resistance maps layers
    this.resistanceCollection.set("name", "resistanceCollection");

    this.corridorCollection = new Collection(); // contains the scenarios corridors layers
    this.corridorCollection.set("name", "corridorCollection");

    this.comparisonMapCollection = new Collection(); // contains the scenarios comparison maps layers
    this.comparisonMapCollection.set("name", "comparisonMapCollection");

    this.optimalPathCollection = new Collection(); // contains the scenarios optimal paths layers
    this.optimalPathCollection.set("name", "optimalPathCollection");

    this.resistanceLayer = new OlLayerImage(); // Resistance map layer - it is a raster
    this.corridorLayer = new OlLayerImage(); // Corridor layer - its a raster
    this.comparisonMapLayer = new OlLayerImage(); // Comparison map layer - its a raster

    this.geoprocessVectorCollection = new Collection(); // contains the scenarios geoprocess vector layers
    this.geoprocessRasterCollection = new Collection(); // contains the scenarios geoprocess raster layers

    this.modifyIP = new Modify({ source: this.intermediatePointSource }); // Modify interaction for Intermediate points

    this.brush = "Polygon"; // Variable that contains the shape for the drawing area tool

    //  layer with the Street View points
    this.SVStyle = MapStylesObject.SVStyle;
    this.SVLayer = new OlLayerVector({
      name: "StreetView",
      source: this.SVPointSource,
      style: this.SVStyle
    });

    this.drawIP = new Draw({
      // Draw interaction for intermediate points
      source: this.auxIPSource,
      type: "Point",
      geometryName: "interP"
    });

    // Vector layer with the project area
    let areaLayer = new OlLayerVector({
      name: "projectArea",
      source: this.areaSource,
      style: MapStylesObject.styleArea,
      zIndex: 0
    });

    let vectorLayerGroup = new OlLayerGroup({
      // Group of processed layers
      layers: this.vectorOfLayers
    });
    vectorLayerGroup.set("name", "vectorLayerGroup");
    let rasterLayersGroup = new OlLayerGroup({
      // Group of raster layers
      layers: this.rasterLayersCollection
    });
    rasterLayersGroup.set("name", "rasterLayersGroup");

    let resistanceGroup = new OlLayerGroup({
      // Group of resistance map layers
      layers: this.resistanceCollection
    });
    resistanceGroup.set("name", "resistanceGroup");

    let corridorGroup = new OlLayerGroup({
      // Group of corridor layers
      layers: this.corridorCollection
    });
    corridorGroup.set("name", "corridorGroup");

    let comparisonMapGroup = new OlLayerGroup({
      // Group of comparison map layers
      layers: this.comparisonMapCollection
    });
    comparisonMapGroup.set("name", "comparisonMapGroup");

    let optimalPathGroup = new OlLayerGroup({
      // group of optimal path layers
      layers: this.optimalPathCollection
    });
    optimalPathGroup.set("name", "optimalPathGroup");

    let geoprocessVectorGroup = new OlLayerGroup({
      // group of geoprocess layers
      layers: this.geoprocessVectorCollection
    });
    geoprocessVectorGroup.set("name", "geoprocessVectorGroup");

    let geoprocessRasterGroup = new OlLayerGroup({
      // group of geoprocess layers
      layers: this.geoprocessRasterCollection
    });
    geoprocessRasterGroup.set("name", "geoprocessRasterGroup");

    let startPointLayer = new OlLayerVector({
      // Start point layer
      source: this.startPointSource,
      style: MapStylesObject.styleStart
    });
    startPointLayer.set("name", "startPointLayer");

    let endPointLayer = new OlLayerVector({
      // Endpoint layer
      source: this.endPointSource,
      style: MapStylesObject.styleEnd
    });
    endPointLayer.set("name", "endPointLayer");

    let iPointsLayer = new OlLayerVector({
      // Intermediate points layer with icon
      source: this.intermediatePointSource,
      style: MapStylesObject.styleInt
    });
    iPointsLayer.set("name", "intermediatePointsLayer");

    let iPointsLayer2 = new OlLayerVector({
      // Intermediate points layer
      source: this.intermediatePointSource
    });
    iPointsLayer2.set("name", "intermediatePointsLayer2");

    let commentsLayer = new OlLayerVector({
      // group of threads/comments layer
      source: this.commentsClusterSource,
      style: MapStylesObject.styleComment
    });
    commentsLayer.set("name", "commentsLayer");

    // Dummy WMS layer to keep coherence in layers position 0
    let wmsDummyLayer = new OlLayerVector({
      source: new OlVectorSource(),
      name: "BaseLayers"
    });

    let highlightedPointsLayer = new OlLayerVector({
      // Highlighted points layer
      source: this.highlightedPointsSource,
      style: MapStylesObject.featureSelectedStyle
    });
    highlightedPointsLayer.set("name", "highlightedPointsLayer");

    // Create Openlayers map
    this.olmap = new OlMap({
      controls: defaultControls({
        attribution: false,
        rotate: false,
        zoom: false
      }),
      interactions: defaultInteractions({
        altShiftDragRotate: false,
        pinchRotate: false
      }),
      loadTilesWhileInteracting: true,
      target: "null",
      layers: [
        wmsDummyLayer, // Layer at position 0 is always our WMS by convention
        rasterLayersGroup, // Raster layers
        areaLayer, // Project area layer
        resistanceGroup, // Resistance map layers
        corridorGroup, // Corridor layers
        comparisonMapGroup, // Comparison map layers
        vectorLayerGroup, // processed Project layers
        optimalPathGroup, // Optimal path layers
        startPointLayer, // Start point layer with icon
        endPointLayer, // Emd point layer with icon
        iPointsLayer, // Intermediate points layer
        iPointsLayer2, // Intermediate points with with icon
        highlightedPointsLayer, // Highlighted features
        this.SVLayer, // Street View Points Layer
        geoprocessRasterGroup, // Geoprocess layers
        geoprocessVectorGroup, // Geoprocess layers
        commentsLayer // Threads/Comments layer
      ],
      view: new OlView({
        // Openlayers view
        center: reprojectPointAsCoords(new Point(defaultCenter)),
        zoom: defaultZoom,
        projection: EPSG_3857,
        rotation: 0,
        minZoom: 3,
        maxZoom: maxZoomLevel
      })
    });

    //Centring the map when coming from 3D
    this.olmap.once("postrender", () => {
      this.updateMap();
      this.centerView();
      this.context.setMap2DReady(true);
    });

    // Set background only one time, when map is rendered
    this.olmap.once("rendercomplete", () => {
      this.context.fetchBackgrounds();
    });

    /* Event listener to "click" event on map.*/
    this.olmap.on("click", async evt => {
      let selected = null;
      this.olmap.forEachFeatureAtPixel(
        evt.pixel,
        (feature, layer) => {
          if (feature) {
            // Check if clicked feature is a comment
            if (layer && layer.get("pf_comment") === true) {
              const featureLenght = feature.get("features").length;

              if (featureLenght === 1) {
                selected = feature.get("features")[0];
                selected.set("hovered", false);
                this.props.threads.clearAllSelectedThreads(this.olmap);
                selected.set("selected", true);
                this.props.threads.setSelectedThreadId(
                  this.olmap,
                  selected.get("pf_thread_id")
                );
              } else if (featureLenght > 1) {
                this.olmap.getView().animate({
                  center: getCenter(feature.getGeometry().getExtent()),
                  duration: 1000,
                  zoom: featureLenght <= 3 ? 16 : featureLenght > 10 ? 12 : 14
                });
              }
            }
          }
        },
        { hitTolerance: 20 }
      );
    });

    /* Event listener to "mousemove" event on map.*/
    let lastFeature = null;
    this.olmap.on("pointermove", async evt => {
      // Comment marker cursor change on hover.
      if (this.olmap && this.context.map2DReady) {
        // TODO perform this forEachFeatureAtPixel only when comments
        // are enabled
        const hit = this.olmap.forEachFeatureAtPixel(
          evt.pixel,
          (feature, layer) => {
            if (layer !== null && layer?.get("pf_comment") === true) {
              // If the mouse is over a comment point, return the feature.
              return [feature.get("features")[0], layer];
            } else {
              return [false, layer];
            }
          },
          {
            layerFilter: candidateLayer => {
              //layerFilter is important since we are only looking for
              //comments and we do not want to check any other kind of
              //feature
              return candidateLayer == commentsLayer;
            }
          }
        );

        if (hit && hit[0]) {
          // If mouse is over the comment point, we change the
          // map cursos style and set the feature property
          // 'hovered' to true, to change the color in the style
          // definition.
          lastFeature = hit[0];
          if (!lastFeature.get("selected")) lastFeature.set("hovered", true);
          this.olmap.getTargetElement().style.cursor = "pointer";
          hit[1].getSource().changed();
        } else {
          lastFeature?.set("hovered", false);
          this.olmap.getTargetElement().style.cursor = "";
          if (hit && hit[1]) hit[1].getSource().changed();
        }
      }
    });

    /**
     * function that manages the drawend event of the draw interaction for the
     * intermediate points, labels the new point and add it to the point vector,
     * also stores this information in the global scenario variable and send the
     * changes to back end
     */

    this.drawIP.on("drawend", e => {
      let format = new GeoJSON();
      let point = e.feature.getGeometry().getCoordinates(); // selected point is readed
      point = format.readFeature({ type: "Point", coordinates: point }); // forced to a Point feature
      // Added the order label as markerID
      let features = this.intermediatePointSource.getFeatures();
      point.set("markerID", (features.length + 1).toString());

      // Add the new feature to the vector source with all the points
      this.intermediatePointSource.addFeature(point);
      this.updateScenarioIntermediatepoints();
    });
    //on modify intermediate_points update scenario
    this.modifyIP.on("modifyend", () =>
      this.updateScenarioIntermediatepoints()
    );

    /* Event listener whenever the map moves.  We have to handle the cluster
     * source and update its distance at the maxZoomLevel
     * */
    this.olmap.on("moveend", async evt => {
      const zoom = this.olmap.getView().getZoom();
      if (zoom >= maxZoomLevel) {
        this.commentsClusterSource.setDistance(1);
      } else {
        this.commentsClusterSource.setDistance(clusterSourcesDistancePx);
      }
    });
  }

  //send intermediate points to the server using  'this.context.updateScenario("intermediate_points", Intermediate)'
  updateScenarioIntermediatepoints = () => {
    let features = this.intermediatePointSource.getFeatures();
    for (let featureCount = 0; featureCount < features.length; featureCount++) {
      unprojectFeature(features[featureCount]);
    }
    features.sort((a, b) => {
      if (a.get("markerID") === undefined || b.get("markerID") === undefined) {
        return 1;
      }
      return parseInt(a.get("markerID")) > parseInt(b.get("markerID")) ? 1 : -1;
    });
    // Get the coordinates vector in the markerID order
    let coordinates = features.map(item => {
      return item.getGeometry().getCoordinates();
    });
    let Intermediate = {
      type: "MultiPoint",
      coordinates: coordinates
    };
    // updateScenario updates the point list in the AppContext and in backend

    this.context.updateScenario("intermediate_points", Intermediate);
    this.auxIPSource.clear(true);
  };

  componentDidMount() {
    this.olmap.setTarget("map");
    // Remove default navegator context menu on map
    this.olmap.getViewport().oncontextmenu = () => {
      return false;
    };
    //   // Aux functions to inspect layers in the map
    window.olmap = this.olmap;
    //   window.getLayers = this.olmap.getLayers().array_;
    //   window.getAllLayers = () =>
    //     this.olmap.getLayers().array_.map(item => item.getLayersArray());

    //   window.toggleLayer = function(child) {
    //     child.setVisible(!child.getVisible());
    //   };
    //   window.getProjectLayers = () => {
    //     let groups = [
    //       "rasterLayersGroup",
    //       "vectorLayerGroup",
    //       "resistanceGroup",
    //       "corridorGroup"
    //     ];
    //     return this.olmap.getLayers().array_.map(item => {
    //       if (groups.some(groupName => groupName === item.get("name"))) {
    //         return item.getLayersArray();
    //       }
    //     });
    //   };
  } // End of componentDidMount

  componentWillUnmount() {
    this.resetMapInteractions();
  }
  /**
   * Function that sets the center of the view in the center of the area extent
   * @param {*} feat_extent
   * @returns
   */
  centerView = feat_extent => {
    try {
      let area_bbox = this.context.state.project.area_bbox;
      let newExtent;
      if (feat_extent) {
        newExtent = feat_extent;
      } else if (area_bbox) {
        let trExtent = transformExtent(area_bbox, EPSG_4326, EPSG_3857);
        newExtent = trExtent;
      } else {
        return;
      }

      this.olmap.getView().fit(newExtent, {
        padding: [50, 50, 50, 50],
        constrainResolution: false,
        duration: 2000,
        rotation: 0
      });
    } catch (e) {
      this.props.enqueueSnackbar(this.props.t("error.Viewcentererror"), {
        variant: "error"
      });
    }
  };

  /**
   * Load point related with the projects.scenario
   */
  loadProjectPoints = () => {
    let scenarios = this.context.state.scenarios;
    let activeSceneario = this.context.state.active_scenario;
    // Important note: I added the exception editMode !== EDITOR_MODES.SCENARIO_POINTS_EDITOR because until we migrate our dialogs and modal to a better system, we have lots of "weird conditions". In this case we use the "editMode" to open other dialogs where we don't want "ghost points" but in editMode = EDITOR_MODES.SCENARIO_POINTS_EDITOR (which is the dialog for Edit Scenario Points) we want them! So we need this exception. If not, no "ghost points" will be shown, which is an issue
    let isEditMode =
      this.context.state.editMode &&
      this.context.state.editMode !== EDITOR_MODES.SCENARIO_POINTS_EDITOR;
    this.clearProjectPointSources();
    let format = new GeoJSON();
    let startP;
    let endP;
    if (
      scenarios.length > 0 &&
      scenarios[activeSceneario]["start_point"] &&
      !isEditMode
    ) {
      startP = reprojectFeature(
        format.readFeature(scenarios[activeSceneario]["start_point"])
      );
      this.startPointSource.addFeature(startP);
    } else if (this.context.project.start_point || false) {
      startP = reprojectFeature(
        format.readFeature(this.context.project.start_point)
      );
      this.startPointSource.addFeature(startP);
    }
    if (
      scenarios.length > 0 &&
      scenarios[activeSceneario]["end_point"] &&
      !isEditMode
    ) {
      endP = reprojectFeature(
        format.readFeature(scenarios[activeSceneario]["end_point"])
      );

      this.endPointSource.addFeature(endP);
    } else if (this.context.project.end_point) {
      endP = reprojectFeature(
        format.readFeature(this.context.project.end_point)
      );
      this.endPointSource.addFeature(endP);
    }
  };

  /**
   * Load project area
   */
  loadProjectArea = () => {
    this.areaSource.clear(true);
    const area = this.context.project.area;
    if (area) {
      const projectedArea = reprojectFeature(new GeoJSON().readFeature(area));
      this.areaSource.addFeature(projectedArea);
    }
  };

  /**
   * Re renders the map visible features
   * Deletes the area and points and re draws them, this allows clean changes
   * caused by the draw and modify interactions
   */
  updateMap() {
    try {
      this.areaSource.clear(true);
      this.intermediatePointSource.clear();
    } catch (e) {}

    this.loadProjectPoints();

    // Draw ghost point if the tab in the scenarios
    if (
      this.context.state.right_tab === SCENARIO_SETTINGS_TAB ||
      this.context.state.isScenarioPointsEditorOpen
    ) {
      this.drawProjectPoints();
    }
    let format = new GeoJSON();

    this.loadProjectArea();

    if (this.context.scenario) {
      if (this.context.state.scenario.intermediate_points) {
        let IntermediatePoints = format.readFeature(
          this.context.state.scenario.intermediate_points
        );
        IntermediatePoints.getGeometry()
          .getPoints()
          .map((item, index) => {
            item = format.readFeature({
              type: "Point",
              coordinates: item.getCoordinates()
            });
            item.set("markerID", (index + 1).toString());
            this.intermediatePointSource.addFeature(reprojectFeature(item));
            return index + 1;
          });
      }
    }
  }

  /**
   * Remove map interactions and resets defaults
   */
  resetMapInteractions = () => {
    this.setState({ drawpoints: false, activeInteraction: "" });
    resetMapInteractions(this.olmap);
  };

  /**
   * Add draw interaction, create new projec option
   * @param {*} resultHandler
   */
  addDrawAreaInteraction = resultHandler => {
    removeActiveDrawInteraction(this.olmap);

    this.setState({ drawpoints: false, activeInteraction: "draw" });
    //this.areaSource.clear();
    let draw;

    if (this.brush === "Polygon") {
      draw = new Draw({
        //source: this.areaSource,
        type: "Polygon"
      });
    } else if (this.brush === "Box" || this.brush === "Circle") {
      draw = new Draw({
        //source: this.areaSource,
        type: "Circle",
        geometryFunction:
          this.brush === "Box" ? createBox() : createRegularPolygon(50)
      });
    }

    this.olmap.addInteraction(draw);
    //disable the mouse DoubleClickZoom
    toggleActiveInteraction(this.olmap, false, DoubleClickZoom);

    draw.on("drawend", e => {
      this.olmap.removeInteraction(draw);

      let cloneFeat = e.feature.clone();
      let trFeat = unprojectFeature(cloneFeat);
      let PArea = {
        type: "Polygon",
        coordinates: trFeat
          .getGeometry()
          .getCoordinates()
          .map(items =>
            items.map(([lon, lat]) => [normalizeLongitude(lon), lat])
          )
      };

      this.context
        .updateArea(PArea)
        .then(result => {
          resultHandler(result);
          this.setState({ drawpoints: false, activeInteraction: "" });
        })
        .catch(() => {
          this.setState({ drawpoints: false, activeInteraction: "" });
        });
    });
  };

  // Method to add a processed layer to the collection
  addVectorLayer = feature => {
    this.vectorOfLayers.push(feature);
  };
  // Method to add a processed raster layer to the collection
  addRasterLayer = feature => {
    this.rasterLayersCollection.push(feature);
  };
  // Method to add a resistance map layer to the collection
  addResistanceLayer = feature => {
    this.resistanceCollection.push(feature);
  };
  // Method to add a corridor layer to the collection
  addCorridorLayer = feature => {
    this.corridorCollection.push(feature);
  };
  // Method to add a comparison map layer to the collection
  addComparisonMapLayer = feature => {
    this.comparisonMapCollection.push(feature);
  };
  // Method to add an optimal path layer to the collection
  addPathLayer = feature => {
    this.optimalPathCollection.push(feature);
  };
  // Method to add a vector layer to the collection
  addGeoprocessVectorLayer = feature => {
    this.geoprocessVectorCollection.push(feature);
  };
  addGeoprocessRasterLayer = feature => {
    this.geoprocessRasterCollection.push(feature);
  };

  intermediatePoint = () => {
    /*
    Function to draw intermediate points
    if this.state.drawpoints == False
    - Disables 3D view
    - Activate intermediate point drawing
    -
    if this.state.drawpoints == true
    - Remove intermediate point drawing interaction
    - Update the intermediate points in the APP Provider
    */
    let isDrawing = getDrawInteraction(this.olmap);
    if (!isDrawing) {
      removeActiveDrawInteraction(this.olmap);
      this.setState({
        drawpoints: true,
        activeInteraction: "drawpoints"
      });
      this.auxIPSource.clear(true);
      this.olmap.addInteraction(this.drawIP);
      this.olmap.addInteraction(this.modifyIP);
    }
  };

  addStartPoint = origin => {
    /*
    Function to draw start points
    on active drawing
    - Disables 3D view
    - Activate start point drawing
    -
    on drawend event
    - Remove start point drawing interaction
    - Update the start points in the APP Provider
    */
    removeActiveDrawInteraction(this.olmap);

    this.setState({
      activeInteraction: "start",
      drawpoints: true
    });
    // Draw interaction for start point
    let drawStartPoint = new Draw({
      //source: this.startPointSource,
      type: "Point",
      //geometryName: "startP",
      stopClick: true
    });
    this.olmap.addInteraction(drawStartPoint);
    drawStartPoint.on("drawend", e => {
      this.olmap.removeInteraction(drawStartPoint);
      this.updateDrawedPoints(e, origin, "start_point");
    });
  };

  /**
   *
   * @param {*} point
   * @param {*} origin
   */
  updateDrawedPoints = (e, origin, name) => {
    let cloneFeat = e.feature.clone();
    let feature = unprojectFeature(cloneFeat);
    let point = {
      type: "Point",
      coordinates: feature.getGeometry().getCoordinates()
    };
    if (origin === "project") {
      this.context
        .updateProject(name, point)
        .then(() => {
          this.setState({ drawpoints: false, activeInteraction: "" });
        })
        .catch(() => {
          this.setState({ drawpoints: false, activeInteraction: "" });
        });
    } else if (origin === "scenario") {
      this.context
        .updateScenario(name, point)
        .then(() => {
          this.setState({ drawpoints: false, activeInteraction: "" });
        })
        .catch(() => {
          this.setState({ drawpoints: false, activeInteraction: "" });
        });
    }
  };

  /**
   * Function to draw end points
   * on active drawing
   *  - Disables 3D view
   *  - Activate end point drawing
   * on drawend event:
   * - Remove end point drawing interaction
   * - Update the end points in the APP Provider
   * @param {*} origin
   */
  addEndPoint = origin => {
    removeActiveDrawInteraction(this.olmap);

    this.setState({ activeInteraction: "end", drawpoints: true });
    // Draw interaction for end points

    let drawEndPoint = new Draw({
      //source: this.endPointSource,
      type: "Point",
      //geometryName: "endP"
      stopClick: true
    });
    this.olmap.addInteraction(drawEndPoint);
    drawEndPoint.on("drawend", e => {
      this.olmap.removeInteraction(drawEndPoint);
      this.updateDrawedPoints(e, origin, "end_point");
    });
  };

  /**
   * Removes any active interaction to ensure there are not 2 features drawing actions enabled at the same time
   * @param {*} resultHandler
   */
  startEdit = resultHandler => {
    this.addDrawAreaInteraction(resultHandler);
  };

  /**
   * Change project area according to the provided Feature.
   *
   * Pathfinder only supports Polygons as areas. In case of a MultiPolygon
   * feature it'll retrieve the first polygon of the MultiPolygon feature.
   *
   * TODO This is HOTFIXED for #2689 but should be rewritten For the HF we
   * continuously check if it's multipoly and perform specific actions. This
   * should simply select the kind of geometry at the start and work with that
   * geometry in all the function.
   *
   * @param {*} feature
   * @returns
   */
  changeArea = feature => {
    try {
      const ftType = feature.getGeometry().getType();
      let coordinates;

      if (ftType === "Polygon") {
        coordinates = feature.getGeometry().getCoordinates();
      } else if (ftType === "MultiPolygon") {
        coordinates = feature.getGeometry().getCoordinates()[0];
      }
      const newArea = {
        type: "Polygon",
        coordinates: coordinates
      };

      return this.context
        .updateArea(newArea)
        .then(res => {
          if (res) {
            this.areaSource.clear(true);
            let projectedArea = reprojectFeature(
              new GeoJSON().readFeature(newArea)
            );
            this.areaSource.addFeature(projectedArea);
            return true;
          }
          return false;
        })
        .then(result => {
          let trExtent;
          if (ftType === "Polygon") {
            trExtent = feature.getGeometry().getExtent();
          } else if (ftType === "MultiPolygon") {
            trExtent = feature.getGeometry().getPolygon(0).getExtent();
          }

          if (feature.crs !== EPSG_3857)
            trExtent = transformExtent(trExtent, EPSG_4326, EPSG_3857);
          this.centerView(trExtent);
          return result;
        })
        .catch(err => {
          this.props.enqueueSnackbar(err.toString(), { variant: "error" });
          return false;
        });
    } catch (e) {
      this.props.enqueueSnackbar(e, { variant: "error" });
      return false;
    }
  };

  /**
   * Change draw brush
   * @param {*} type
   * @param {*} resultHandler
   */
  changeBrush = (type, resultHandler) => {
    this.brush = type;
    this.addDrawAreaInteraction(resultHandler);
  };

  /**
   * Get geoprocess layer by id
   * @param {*} id
   * @returns
   */
  getGeoprocessLayerById = id => {
    let layer = null;
    if (this.geoprocessVectorCollection) {
      this.geoprocessVectorCollection.forEach(lyr => {
        if (id === lyr.get("id")) {
          layer = lyr;
        }
      });
      this.geoprocessRasterCollection.forEach(lyr => {
        if (id === lyr.get("id")) {
          layer = lyr;
        }
      });
    }
    return layer;
  };

  /**
   * Toggle show and hide circles pylons in 2D. Assigns a new FeatureProperty
   * into the LineString of the path named showNodes. This property can be
   * used by the renderer to decide if nodes should be styled or not.
   *
   * @param {bool} isShown show or hide the labels
   */
  togglePylons2D = isShown => {
    // Disable Pylons toolbar if not is opened
    // if (this.state.toggle2DToolbar.includes("pylons_2d_tools")) {
    //   this.set2DToolbar("pylons_2d_tools");
    // }

    let layers = this.optimalPathCollection.getArray();
    for (const pathLayer of layers) {
      let ls = getMainLineStringFromPathLayer(pathLayer);
      if (ls) {
        ls.set("showNodes", isShown);
      }
    }
  };

  /**
   * Toggle show and hide labels in 2D. Assigns a new FeatureProperty
   * into the LineString of the path named showLabels. This property can be
   * used by the renderer to decide if nodes should be styled or not.
   *
   * @param {bool} isShown show or hide the labels
   */
  toggleLabels2D = isShown => {
    let layers = this.optimalPathCollection.getArray();
    for (const pathLayer of layers) {
      let ls = getMainLineStringFromPathLayer(pathLayer);
      if (ls) {
        ls.set("showLabels", isShown);
      }
    }
  };

  /**
   * Toggle show and hide routing widthin 2D. Assigns a new FeatureProperty
   * into the LineString of the path named showRoutingWidth. This property can be
   * used by the renderer to decide if nodes should be styled or not.
   *
   * @param {bool} isShown show or hide the labels
   */
  toggleShowRoutingWidth2D = isShown => {
    let layers = this.optimalPathCollection.getArray();
    for (const pathLayer of layers) {
      let ls = getMainLineStringFromPathLayer(pathLayer);
      if (ls) {
        ls.set("showRoutingWidth", isShown);
      }
    }
  };

  /**
   * Function that takes the openlayers map component from MapContext and
   * generates a screenshow of the openlayers canvas
   * it also adds a watermark to de bottom right corner
   */
  screenshot2D = (projectName, userName) => {
    this.olmap.once("rendercomplete", async () => {
      let mapCanvas = document.createElement("canvas");
      let size = this.olmap.getSize();
      mapCanvas.width = size[0];
      mapCanvas.height = size[1];
      let mapContext = mapCanvas.getContext("2d");

      Array.prototype.forEach.call(
        this.olmap.getViewport().querySelectorAll(".ol-layer canvas"),
        canvas => {
          if (canvas.width > 0) {
            let opacity = canvas.parentNode.style.opacity;
            mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
            let transform = canvas.style.transform;
            // Get the transform parameters from the style's transform matrix
            let matrix = transform
              .match(/^matrix\(([^]*)\)$/)[1]
              .split(",")
              .map(Number);
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
              mapContext,
              matrix
            );
            mapContext.drawImage(canvas, 0, 0);
          }
        }
      );

      const watermarkedScreenshotBlob = await generateMapScreenshot({
        mapCanvas,
        projectName,
        userName
      });

      saveBlobAsImage(
        watermarkedScreenshotBlob,
        `${projectName}_screenshot.png`
      );
    });
    this.olmap.renderSync();
  };

  /**
   * Function to restore the project points for the scenario
   *  It sets the scenario points to null, the application will take the project
   *  start/end points as the valid ones
   * @param {*} point
   */
  restoreProjectPoints = () => {
    this.resetMapInteractions();
    this.updateMap();
  };

  /**
   * Point Functions, functions related to the scenario and project points
   */
  drawScenarioPoints = () => {
    let format = new GeoJSON();
    let activeSceneario = this.context.state.active_scenario;
    let scenarios = this.context["state"]["scenarios"];
    if (
      scenarios.length > 0 &&
      scenarios[activeSceneario]["start_point"] &&
      this.context.state.editMode === EDITOR_MODES.NO_EDITOR
    ) {
      let startP = format.readFeature(
        scenarios[activeSceneario]["start_point"]
      );
      this.startPointSource.addFeature(startP);
    }
    if (
      scenarios.length > 0 &&
      scenarios[activeSceneario]["end_point"] &&
      this.context.state.editMode === EDITOR_MODES.NO_EDITOR
    ) {
      let endP = format.readFeature(scenarios[activeSceneario]["end_point"]);
      this.endPointSource.addFeature(endP);
    }
  };

  /**
   * Draw project points
   * @param {*} type
   */
  drawProjectPoints = (type = "ghost") => {
    let format = new GeoJSON();
    let startPoint = this.context.project.start_point;
    let endPoint = this.context.project.end_point;
    // Start point
    if (startPoint) {
      let startP = reprojectFeature(format.readFeature(startPoint));
      if (type === "ghost") startP.set("type", type);
      this.startPointSource.addFeature(startP);
    }
    // End Point
    if (endPoint) {
      let endP = reprojectFeature(format.readFeature(endPoint));
      if (type === "ghost") endP.set("type", type);
      this.endPointSource.addFeature(endP);
    }
  };

  /**
   * Clear project point
   */
  clearProjectPointSources = () => {
    this.startPointSource.clear(true);
    this.endPointSource.clear(true);
  };

  /**
   * Set Start/End project points
   * @param {*} type
   * @param {*} point
   */
  setPoint = (type, point) => {
    let format = new GeoJSON();
    this.context.updateScenario(type, format.readFeature(point));

    if (type === "end_point") {
      this.endPointSource.addFeature(format.readFeature(point));
    }
  };

  /**
   * Component Did Update
   * @param {*} prevProps
   * @param {*} prevState
   * @param {*} snapshot
   */
  componentDidUpdate() {
    if (this.context.project.length > 0 || this.context.project.id) {
      if (this.state.currentProject !== this.context.project.id) {
        this.setState(
          { currentProject: this.context.project.id },
          this.updateMap
        );
        // Remove all interaction if change the project
        resetMapInteractions(this.olmap);
        // remove all overlays
        this.olmap.getOverlays().clear(true);
      } else {
        this.updateMap();
      }
    }
  }

  setStreetViewObject = streetViewObject => {
    this.streetViewObject = streetViewObject;
  };

  getStreetViewObject = () => {
    return this.streetViewObject;
  };

  /**
   * Handle pointer move.
   */
  pointerSVMoveHandler = evt => {
    if (evt.dragging) {
      return;
    }
    let helpMsg = this.props.t("StreetView.start");
    if (this.streetViewObject) {
      helpMsg = this.props.t("StreetView.continue");
    }

    SVTooltipElement.innerHTML = helpMsg;
    SVTooltip.setPosition(evt.coordinate);
    SVTooltipElement.classList.remove("hidden");
  };

  /**
   * Creates a new StreetView tooltip
   */
  createSVTooltip = () => {
    if (SVTooltipElement) {
      if (SVTooltipElement.parentNode) {
        SVTooltipElement.parentNode.removeChild(SVTooltipElement);
      }
    }
    SVTooltipElement = document.createElement("div");
    SVTooltipElement.className = "ol-tooltip hidden";
    SVTooltip = new Overlay({
      element: SVTooltipElement,
      offset: [15, 0],
      positioning: "center-left"
    });
    this.olmap.addOverlay(SVTooltip);

    this.olmap.on("pointermove", this.pointerSVMoveHandler);
    this.olmap.getViewport().addEventListener("mouseout", () => {
      if (SVTooltipElement) {
        SVTooltipElement.classList.add("hidden");
      }
    });
  };

  /**
   * Toggle Street View Tool
   */
  streetView = () => {
    this.setState({ isStreeView: !this.state.isStreeView }, () => {
      if (this.state.isStreeView) {
        // Clean StreeView. Masure and StreeView are not compatible at the same time
        this.setState({
          toggleMeasureToolbar: false,
          togglePylonsToolbar: false
        });
        // Click map listener
        this.olmap.on("singleclick", this.singleClickSVCallBack);
        // Add Move pointer ToolTip
        this.createSVTooltip();
      } else {
        this.olmap.un("singleclick", this.singleClickSVCallBack);
        // Clear StreetView Point
        this.cleanSVPoint();
      }
    });
  };

  /**
   * Update Street View Marker
   * @param {*} heading
   */
  updateSVPov = heading => {
    this.SVStyle.getImage().setRotation(heading * (Math.PI / 180));
    this.SVLayer.changed();
  };

  /**
   * Update Point from SV Viewer
   * @param {*} lng
   * @param {*} lat
   */
  updateSVPoint = (lng, lat) => {
    this.setState({ svCoord: [lat, lng] });
    this.SVPointSource.clear(true);
    let newPoint = transform([lng, lat], EPSG_4326, EPSG_3857);
    let marker = new Feature(new Point(newPoint));
    this.SVPointSource.addFeature(marker);
  };
  /**
   * Clean Stree View Source
   */
  cleanSVPoint = (close = false) => {
    this.setState({ svCoord: [] });
    this.SVPointSource.clear(true);
    this.olmap.un("singleclick", this.singleClickSVCallBack);
    if (close) {
      this.setState({ isStreeView: false });
    }
    // Remove cursor Tooltip
    this.olmap.removeOverlay(SVTooltip);
    this.olmap.un("pointermove", this.pointerSVMoveHandler);
    // Remove StreetView Object
    this.setStreetViewObject(null);
  };

  /**
   * Openlayer Map Click Event
   * @param {*} evt
   */
  singleClickSVCallBack = evt => {
    this.SVPointSource.clear(true);
    // convert coordinate to EPSG-4326
    let googelformat = transform(
      evt.coordinate,
      EPSG_3857,
      EPSG_4326
    ).reverse();
    this.setState({ svCoord: googelformat });
    // Draw point in map
    let marker = new Feature(new Point(evt.coordinate));
    this.SVPointSource.addFeature(marker);
  };

  /**
   * Given a lon lat zoom at that point with the maximum zoom accepted
   * @param {float} lon longitude to zoom to
   * @param {float} lat latitude to zoom to
   */
  zoomToCoordinates = (lon, lat) => {
    const view = this.olmap.getView();
    const point = reprojectPointAsCoords(new Point([lon, lat]));
    view.setZoom(view.getMaxZoom());
    view.setCenter(point);
  };

  /**
   * Method to clear all the features in the
   * highlighted layer.
   */
  clearHighlightedPoints = () => {
    this.highlightedPointsSource.clear();
  };

  /**
   * Method to add a highlighted mark in the highlighted
   * layer. It calls the method to draw a circle and adds that
   * feature to the highlighted layer.
   *
   * @param {number} lon
   * @param {number} lat
   */
  addHighlightedPoint = (lon, lat) => {
    // Draw a circle with radius in meters.
    const highlightCircle = olCircleInMeters(this.olmap, lon, lat, 60);
    this.highlightedPointsSource.addFeature(highlightCircle);

    // Draw a point (disabled but here if we need it)
    // const point = reprojectPointAsCoords(new Point([lon, lat]));
    // this.highlightedPointsSource.addFeature(new Feature(new Point(point)));
  };

  render() {
    return (
      <MapContext.Provider
        value={{
          state: this.state, // Global state of the component
          editMode: this.context.editMode, // Edit mode status
          resetMapInteractions: this.resetMapInteractions, // function to stop feature interactions
          startEdit: this.startEdit, // Function to draw the start point
          changeArea: this.changeArea, // Function to change the area source
          center: this.centerView, // Function to center the view on the area
          addEndPoint: this.addEndPoint, // end point drawing method
          addStartPoint: this.addStartPoint, // start point drawing method
          intermediatePoint: this.intermediatePoint, // intermediate point drawing method
          intermediatePointSource: this.intermediatePointSource,
          addHighlightedPoint: this.addHighlightedPoint, // highlighted features like pylons
          clearHighlightedPoints: this.clearHighlightedPoints,
          commentsSouce: this.commentsSource,
          updateScenarioIntermediatepoints:
            this.updateScenarioIntermediatepoints,
          addVectorLayer: this.addVectorLayer, // function to add a processed layer
          addRasterLayer: this.addRasterLayer, // function to add a processed raster layer
          addResistanceLayer: this.addResistanceLayer, // function to add a resistance map layer to its layer group
          addCorridorLayer: this.addCorridorLayer, // function to add a corridor layer to its layer group
          addComparisonMapLayer: this.addComparisonMapLayer, // function to add a comparision map layer to its layer group
          addPathLayer: this.addPathLayer, // function to add a optimal path layer to its layer group
          addGeoprocessVectorLayer: this.addGeoprocessVectorLayer, // function to add a geoprocess layer to its layer group
          addGeoprocessRasterLayer: this.addGeoprocessRasterLayer,
          changeBrush: this.changeBrush, // Function to change the draw are brush shape
          activeInteraction: this.state.activeInteraction, // variable to check active states in draw
          olmap: this.olmap,
          setMeasureToolbar: this.setMeasureToolbar,
          setPylonsToolbar: this.setPylonsToolbar,
          screenshot2D: this.screenshot2D,
          togglePylons2D: this.togglePylons2D,
          toggleLabels2D: this.toggleLabels2D,
          toggleShowRoutingWidth2D: this.toggleShowRoutingWidth2D,
          getGeoprocessLayerById: this.getGeoprocessLayerById,
          restoreProjectPoints: this.restoreProjectPoints,
          pointFunctions: {
            drawProjectPoints: this.drawProjectPoints,
            drawScenarioPoints: this.drawScenarioPoints,
            clearPoints: this.clearPoints,
            setPoint: this.setPoint
          },
          setStreetViewObject: this.setStreetViewObject,
          getStreetViewObject: this.getStreetViewObject,
          streetView: this.streetView,
          cleanSVPoint: this.cleanSVPoint,
          updateSVPoint: this.updateSVPoint,
          updateSVPov: this.updateSVPov,
          zoomToCoordinates: this.zoomToCoordinates
        }}
      >
        {this.props.children}
      </MapContext.Provider>
    );
  }
}
let MapConsumer = MapContext.Consumer;
export { MapConsumer };
export default withTranslation()(withSnackbar(MapProvider));
MapProvider.contextType = AppContext;
