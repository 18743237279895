import React, { createContext, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { getState, getTasks as getTasksService } from "services/task";
import {
  FAILURE,
  KILLED,
  PENDING,
  WARNING,
  QUEUED,
  RUNNING,
  SUCCESS,
  UNKNOWN
} from "assets/global";
import { AppContext } from "AppProvider";

export const ProcessContext = createContext();

/**
 * Converts an array of tasks into an object with task IDs as keys.
 *
 * @param {Array} tasks - The array of tasks to convert.
 * @returns {Object} - The tasks object with task IDs as keys.
 */
function tasksArrayToObject(tasks) {
  const tasksObject = {};

  (tasks || [])
    .filter(task => task?.task_id)
    .forEach(task => {
      tasksObject[task.task_id] = task;
    });

  return tasksObject;
}

/**
 * Returns a numerical value based on the given state.
 *
 * @param {string} state - The state value.
 * @returns {number} - The numerical value corresponding to the state.
 */
function getStateValue(state) {
  switch (state) {
    case SUCCESS:
    case FAILURE:
    case WARNING:
    case KILLED:
      return 5;
    case RUNNING:
      return 4;
    case QUEUED:
      return 3;
    case PENDING:
      return 2;
    case UNKNOWN:
    default:
      return 1;
  }
}

/**
 * Merges two task objects and returns a new merged task object.
 *
 * @param {Object} taskA - The first task object.
 * @param {Object} taskB - The second task object.
 * @returns {Object} The merged task object.
 */
function safeTaskObjectMerger(taskA, taskB) {
  // First, detect which one has the most advanced state
  // UNKNOWN = PENDING > QUEUED > RUNNING > SUCCESS = FAILURE = KILLED
  let isTaskATheMostRecent = false;

  const taskAStateValue = getStateValue(taskA?.state ?? UNKNOWN);
  const taskBStateValue = getStateValue(taskB?.state ?? UNKNOWN);

  if (taskAStateValue > taskBStateValue) {
    isTaskATheMostRecent = true;
  } else if (taskAStateValue === taskBStateValue) {
    isTaskATheMostRecent = false;
  } else {
    isTaskATheMostRecent = false;
  }

  let mergedDetails,
    mergedFailureCallback,
    mergedKilledCallback,
    mergedSuccessCallback,
    mergedLogFile,
    mergedRuntime,
    mergedState;

  if (isTaskATheMostRecent) {
    mergedDetails = taskA?.details ?? taskB?.details ?? null;
    mergedFailureCallback =
      taskA?.failureCallback ?? taskB?.failureCallback ?? null;
    mergedKilledCallback =
      taskA?.killedCallback ?? taskB?.killedCallback ?? null;
    mergedSuccessCallback =
      taskA?.successCallback ?? taskB?.successCallback ?? null;
    mergedLogFile = taskA?.log_file ?? taskB?.log_file ?? null;
    mergedRuntime = taskA?.runtime ?? taskB?.runtime ?? 0;
    mergedState = taskA?.state ?? taskB?.state ?? UNKNOWN;
  } else {
    mergedDetails = taskB?.details ?? taskA?.details ?? null;
    mergedFailureCallback =
      taskB?.failureCallback ?? taskA?.failureCallback ?? null;
    mergedKilledCallback =
      taskB?.killedCallback ?? taskA?.killedCallback ?? null;
    mergedSuccessCallback =
      taskB?.successCallback ?? taskA?.successCallback ?? null;
    mergedLogFile = taskB?.log_file ?? taskA?.log_file ?? null;
    mergedRuntime = taskB?.runtime ?? taskA?.runtime ?? 0;
    mergedState = taskB?.state ?? taskA?.state ?? UNKNOWN;
  }

  // Then, merge the tasks
  const mergedTask = {
    details: mergedDetails,
    failureCallback: mergedFailureCallback,
    killedCallback: mergedKilledCallback,
    successCallback: mergedSuccessCallback,
    log_file: mergedLogFile,
    runtime: mergedRuntime,
    state: mergedState,
    task_id: taskA.task_id // The task_id is the same for both tasks
  };

  return mergedTask;
}

/**
 * A provider component for managing process-related data and functions.
 * It stores all the process data and provides functions to add, list, and clear processes.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} The rendered component.
 */
export default function ProcessProvider({ children }) {
  // Using the appContext to store the data is a temporary solution until we stop using classes in the AppContext and make sure every file using the AppContext is not a class component
  const appContext = useContext(AppContext);

  /**
   * Retrieves tasks from the server and updates the process state.
   * @returns {void}
   * @example
   * getTasks();
   */
  function getTasks() {
    getTasksService()
      .then(res => {
        try {
          setTasks(tasksArrayToObject(res));
        } catch {
          console.log("No previous process found");
        }
      })
      .catch(err => console.log(err));
  }

  /**
   * Adds a new process to the process list.
   *
   * @param {Object} options - The options for the new process.
   * @param {string} [options.task_id="0"] - The ID of the task.
   * @param {string} [options.details="no details"] - The details of the process.
   * @param {string} [options.state="PENDING"] - The state of the process.
   * @param {function} [options.successCallback] - The success callback function.
   * @param {function} [options.failureCallback] - The failure callback function.
   * @param {function} [options.killedCallback] - The killed callback function.
   * @returns {void}
   * @example
   * addProcess({
   *  task_id: "894e0084-ea31-48af-8e77-a3b1e592a20f",
   * details: "This is a new process",
   * state: "PENDING",
   * successCallback: function () {},
   * failureCallback: function () {},
   * killedCallback: function () {}
   * });
   */
  function addProcess({
    task_id = "0",
    details = "no details",
    state = "PENDING",
    successCallback = function () {},
    failureCallback = function () {},
    killedCallback = function () {}
  }) {
    // Using the appContext to store the data is a temporary solution until we stop using classes in the AppContext and make sure every file using the AppContext is not a class component
    appContext.addProcess({
      task_id: task_id,
      details: details,
      state: state,
      successCallback: successCallback,
      failureCallback: failureCallback,
      killedCallback: killedCallback
    });
  }

  /**
   * Refreshes the process state and details for a specific task.
   * @param {Object} options - The options for refreshing the process.
   * @param {string} options.task_id - The ID of the task to refresh.
   * @returns {Promise<Object>} - The updated process object.
   * @example
   * refreshProcess({ task_id: "894e0084-ea31-48af-8e77-a3b1e592a20f" });
   */
  async function refreshProcess({ task_id }) {
    const clonedTasks = { ...appContext.state.tasks };
    const foundTask = clonedTasks[task_id];

    if (!foundTask) {
      return; // Process not found
    }

    const newProcessState = await getState(task_id);
    const newProcessValue = safeTaskObjectMerger(foundTask, newProcessState);
    return newProcessValue;
  }

  /**
   * Logs the process variable to the console.
   * @returns {void}
   * @example
   * listProcess();
   */
  function listProcess() {
    console.log(appContext.state.tasks);
  }

  /**
   * Sets the tasks in the app context.
   * @param {Function|Object} tasks - The tasks to set.
   * @returns {void}
   * @example
   * setTasks({});
   * setTasks((tasks) => ({ ...tasks, "newTask": { state: "PENDING" } }));
   */
  function setTasks(tasks) {
    if (typeof tasks === "function") {
      appContext.setTasks(tasks(appContext.state.tasks));
    } else {
      appContext.setTasks(tasks);
    }
  }

  /**
   * Sets the state of the process log.
   *
   * @param {boolean} state - The new state of the process log.
   * @returns {void}
   * @example
   * setProcessLog(true);
   * setProcessLog(false);
   */
  function setProcessLog(state) {
    appContext.setProcessLog(state);
  }

  useEffect(() => {
    getTasks();
    // This is the equivalent of componentDidMount, so we only want to run this once (no dependencies)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProcessContext.Provider
      value={{
        // Using state to keep compatibility with older systems using .state to access the state of a context, in the future this should be removed
        state: {
          process: appContext.state.tasks,
          process_log_open: appContext.state.tasksLogOpen
        },
        getTasks,
        setTasks,
        addProcess,
        refreshProcess,
        setProcessLog,
        listProcess
      }}
    >
      {children}
    </ProcessContext.Provider>
  );
}

ProcessProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ])
};

/**
 * A consumer component that provides access to the ProcessContext.
 *
 * @component
 */
export const ProcessConsumer = ProcessContext.Consumer;
