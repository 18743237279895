import * as Sentry from "@sentry/browser";

import OlLayerVector from "ol/layer/Vector";
import GeoJSON from "ol/format/GeoJSON.js";

import {
  getOLLayerByName,
  getOLLayerSource
} from "components/Dashboard2D/OLLayerUtils";
import { reprojectFeature } from "components/Dashboard2D/SrcTransform";
import { getCenter } from "ol/extent";
import { EPSG_4326 } from "../assets/global";
import { register } from "ol/proj/proj4";
import proj4 from "proj4";
import { fromLonLat } from "ol/proj";
import { round } from "./MathTools";

/**
 * Method to add a comment in the map given the coordinates and
 * the comment information.
 *
   It adds some comment related information to the point and
   then adds the new point to the comments cluster souce.
   
 * @param {object} map Reference to the OL map object.
 * @param {array} pointCoordinates Lon and Lat of the point for the comment.
 * @param {*} threadId Comment systme thread id.
 * @param {*} scenarioId Scenario for the comment.
 * @param {*} pojectId Project for the comment.
 */
export const addNewCommentToMap = (
  map,
  pointCoordinates,
  threadId,
  color,
  scenarioId,
  pojectId
) => {
  try {
    const layer = getOLLayerByName(map, "commentsLayer");
    layer.set("pf_comment", true);

    if (layer instanceof OlLayerVector) {
      const format = new GeoJSON();
      const point = format.readFeature({
        type: "Point",
        coordinates: [pointCoordinates[0], pointCoordinates[1]]
      });

      // Add extra properties for the comment.
      point.set("pf_comment", true);
      point.set("pf_thread_id", threadId);
      point.set("pf_thread_color", color);
      point.set("pf_thread_scenario_id", scenarioId);
      point.set("pf_thread_project_id", pojectId);
      point.set("selected", true);

      // Add the comment point feature to the comments layer.
      getOLLayerSource(layer).addFeature(point);
    }
  } catch (err) {
    console.log(err);
    Sentry.captureException(err);
  }
};

export const addCommentToMap = (
  map,
  pointCoordinates,
  threadId,
  color,
  scenarioId,
  pojectId
) => {
  try {
    const layer = getOLLayerByName(map, "commentsLayer");
    layer.set("pf_comment", true);

    if (layer instanceof OlLayerVector) {
      const format = new GeoJSON();

      const point = format.readFeature({
        type: "Point",
        coordinates: [pointCoordinates[0], pointCoordinates[1]]
      });

      // Add extra properties for the comment.
      point.set("pf_comment", true);
      point.set("pf_thread_id", threadId);
      point.set("pf_thread_color", color);
      point.set("pf_thread_scenario_id", scenarioId);
      point.set("pf_thread_project_id", pojectId);
      point.set("selected", true);

      // Add the comment point feature to the comments layer.
      getOLLayerSource(layer).addFeature(reprojectFeature(point));
    }
  } catch (err) {
    console.log(err);
    Sentry.captureException(err);
  }
};

export const removeCommentFromMap = (map, threadId) => {
  const layer = getOLLayerByName(map, "commentsLayer");

  if (layer instanceof OlLayerVector) {
    const comments = getOLLayerSource(layer).getFeatures();
    const comment = comments.find(
      comment => Number(comment.get("pf_thread_id")) === Number(threadId)
    );
    getOLLayerSource(layer).removeFeature(comment);
  }
};

/**
 * Method to add a group of comments to the map given
 * a map reference and a list of thread objects.
 *
 * It iterates the array of threads to create the point
 * feature based on the point poperty. Then it adds
 * exra info to the feature and adds it to the comments
 * cluster layer.
 *
 * @param {object} map The reference to the map.
 * @param {array} threads Array of threads fetched from back-end.
 */
export const loadAllCommentsToMap = (map, threads) => {
  try {
    const layer = getOLLayerByName(map, "commentsLayer");
    layer.set("pf_comment", true);

    if (layer instanceof OlLayerVector) {
      threads.forEach(thread => {
        const format = new GeoJSON();
        const point = format.readFeature(thread.point);
        const commentPoint = reprojectFeature(point);

        // Add extra properties for the comment.
        commentPoint.set("pf_comment", true);
        commentPoint.set("pf_thread_id", thread.id);
        commentPoint.set("pf_thread_color", thread.color);
        commentPoint.set("pf_thread_scenario_id", thread.scenario);
        commentPoint.set("pf_thread_project_id", thread.project);

        // Add the comment point feature to the comments layer.
        getOLLayerSource(layer).addFeature(commentPoint);
      });
    }
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * Method to remove all the commnets from the map. It
 * looks for the comments layer and gets the source
 * to execute a clear().
 *
 * @param {object} map The reference to the map.
 */
export const removeAllCommentsFromMap = map => {
  try {
    const layer = getOLLayerByName(map, "commentsLayer");

    if (layer instanceof OlLayerVector) {
      getOLLayerSource(layer).clear();
    }
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const setSelectedThreadMark = (map, threadId, value) => {
  try {
    const layer = getOLLayerByName(map, "commentsLayer");

    if (layer instanceof OlLayerVector) {
      const comments = getOLLayerSource(layer).getFeatures();
      const comment = comments.find(
        comment => Number(comment.get("pf_thread_id")) === Number(threadId)
      );
      comment.set("selected", value);
      getOLLayerSource(layer).changed();
    }
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const removeAllSelectedThreadMarks = map => {
  try {
    const layer = getOLLayerByName(map, "commentsLayer");

    if (layer instanceof OlLayerVector) {
      const comments = getOLLayerSource(layer).getFeatures();

      for (const comment of comments) {
        const selected = comment.get("selected");
        if (selected) comment.set("selected", false);
      }
      getOLLayerSource(layer).changed();
    }
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const zoomToThread = (map, threadId) => {
  const layer = getOLLayerByName(map, "commentsLayer");

  let threadFeature;

  if (layer instanceof OlLayerVector) {
    const comments = getOLLayerSource(layer).getFeatures();
    const comment = comments.find(
      comment => Number(comment.get("pf_thread_id")) === Number(threadId)
    );
    threadFeature = comment;
  }

  if (threadFeature) {
    const view = map.getView();
    const point = getCenter(threadFeature.getGeometry().getExtent());
    const duration = 2000;
    const zoom = view.getZoom();
    let parts = 2;
    let called = false;

    function callback(complete) {
      --parts;
      if (called) {
        return;
      }
      if (parts === 0 || !complete) {
        called = true;
      }
    }

    view.animate(
      {
        center: point,
        duration: duration
      },
      callback
    );

    view.animate(
      {
        zoom: zoom,
        duration: duration / 2
      },
      {
        zoom: 17,
        duration: duration / 2
      },
      callback
    );
  }
};

/**
 * The wgs84ToUTM function converts a longitude/latitude pair to UTM coordinates.
 *
 * @param lon_deg Get the zone
 * @param lat_deg Get the latitude in degrees
 * @param zone Determine the utm zone. By default, the value is -1, which means to autodetect the UTM zone
 *
 * @return A zone and a point
 */
export function wgs84ToUTM(lon_deg, lat_deg, zone = -1) {
  if (zone === -1) zone = parseInt(((lon_deg + 180) / 6) % 60) + 1;

  // define proj4_defs for easy uses
  // "EPSG:4326" for long/lat degrees, no projection
  // "EPSG:AUTO" for UTM 'auto zone' projection
  proj4.defs([
    [
      EPSG_4326,
      "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees"
    ],
    ["EPSG:UTM", "+proj=utm +zone=" + zone + " +datum=WGS84 +units=m +no_defs"]
  ]);
  register(proj4);
  // usage:
  // conversion from (long/lat) to UTM (E/N)
  let point = fromLonLat([lon_deg, lat_deg], "EPSG:UTM");
  return [zone, point];
}

/**
 * The getDistanceAndAngle function takes in a path_data array, an index iPylon, and a utm_zone.
 * It returns the distance between the current pylon and the next pylon (distance2),
 * as well as the angle between those two points (angle).

 *
 * @param path_data Get the coordinates of the pylons       
 * @param iPylon Determine which pylon to calculate the distance and angle for
 * @param utm_zone Convert the coordinates to utm
 * @return The distance between the pylons
 */
export function getDistanceAndAngle(path_data, iPylon, utm_zone) {
  // transform coordinates to metric, for calculations
  const result1 = wgs84ToUTM(
    path_data[iPylon][0],
    path_data[iPylon][1],
    utm_zone
  );
  utm_zone = result1[0];
  const utm_coords1 = result1[1];

  const result2 = wgs84ToUTM(
    path_data[iPylon + 1][0],
    path_data[iPylon + 1][1],
    utm_zone
  );
  const utm_coords2 = result2[1];
  const v_next = [
    utm_coords2[0] - utm_coords1[0],
    utm_coords2[1] - utm_coords1[1]
  ];
  const distance2 = Math.sqrt(v_next[0] * v_next[0] + v_next[1] * v_next[1]);

  // angle at iPylon
  if (iPylon === 0) return [distance2, 0.0, utm_zone];

  const result0 = wgs84ToUTM(
    path_data[iPylon - 1][0],
    path_data[iPylon - 1][1],
    utm_zone
  );
  const utm_coords0 = result0[1];
  const v_prev = [
    utm_coords1[0] - utm_coords0[0],
    utm_coords1[1] - utm_coords0[1]
  ];
  // normalize vectors
  const distance1 = Math.sqrt(v_prev[0] * v_prev[0] + v_prev[1] * v_prev[1]);
  v_prev[0] /= distance1;
  v_prev[1] /= distance1;
  v_next[0] /= distance2;
  v_next[1] /= distance2;

  let v_product = v_prev[0] * v_next[0] + v_prev[1] * v_next[1];
  // set vector product to a min max of [-1,1]
  // because of some inaccuracies
  v_product = Math.min(Math.max(v_product, -1), 1);

  const angle = (Math.acos(v_product) * 180) / Math.PI;

  return [distance2, angle, utm_zone];
}

/**
 * The generate_pylon_properties function takes in a pathId and pathsData,
 * and returns an array of objects containing the properties of each pylon.
 *
 *
 * @param pathId Identify the path that is being generated
 * @param pathsData Get the path coordinates
 *
 * @return An array of objects
 */
export function generate_pylon_properties(pathId, pathsData) {
  const prop_list = [];
  let path_data = pathsData[0].path.coordinates;
  if (pathsData[0].path3D) path_data = pathsData[0].path3D.coordinates;
  else if (pathsData[0].path_3d) path_data = pathsData[0].path_3d.coordinates;

  if (path_data.length === 0) return prop_list;

  const is_3D = path_data[0].length === 3;
  let utm_zone = -1; // initially not defined, but then kept consistent

  for (let iPylon = 0; iPylon < path_data.length; ++iPylon) {
    let distance = 0;
    let angle = 0;
    if (iPylon < path_data.length - 1) {
      let result = getDistanceAndAngle(path_data, iPylon, utm_zone);
      distance = result[0];
      angle = result[1];
      utm_zone = result[2];
    }

    prop_list.push({
      number: iPylon,
      longitude: round(path_data[iPylon][0], 8),
      latitude: round(path_data[iPylon][1], 8),
      elevation: is_3D ? path_data[iPylon][2] : "-",
      distance: round(distance, 1),
      angle: round(angle, 1)
    });
  }

  return prop_list;
}

export const zoomToFeature = (map, feature) => {
  if (feature) {
    // Get the feature's geometry extent.
    const extent = feature.getGeometry();
    const view = map.getView();
    const duration = 1000;

    view.fit(extent, {
      duration: duration,
      padding: [100, 100, 100, 100]
    });
  }
};

/**
 * Normalizes a given longitude to the range [-180, 180].
 *
 * This function ensures that the longitude value wraps around correctly,
 * so that it always falls within the standard range for geographic coordinates.
 *
 * OpenStreetMaps uses the Web Mercator projection, which wraps the map around
 * the globe, so that the map repeats every 360 degrees in longitude.
 * It goes from -180 to 180 degrees, with 0 degrees at the center.
 *
 * This function normalizes a given longitude value to this range.
 *
 * @param {number} longitudeToNormalize - The longitude value to normalize.
 * @returns {number} The normalized longitude value within the range [-180, 180].
 */
export function normalizeLongitude(longitudeToNormalize) {
  return ((((longitudeToNormalize + 180) % 360) + 360) % 360) - 180;
}
