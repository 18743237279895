/**
 * The round function takes a number and rounds it to the specified decimal place.
 *
 * @param num Pass in the number that will be rounded
 * @param decimals Determine how many decimal places the number will be rounded to
 *
 * @return {string}
 */
export function round(num, decimals) {
  const factor = 10 ** decimals;
  return (Math.round(num * factor) / factor).toFixed(decimals);
}

/**
 * Returns the number of decimals of a given number.
 *
 * @param {number} n
 *
 * @returns {number}
 */
export function decimalPlaces(n) {
  // Make sure it is a number and use the builtin number -> string.
  var s = "" + +n;
  // Pull out the fraction and the exponent.
  var match = /(?:\.(\d+))?(?:[eE]([+\\-]?\d+))?$/.exec(s);
  // NaN or Infinity or integer.
  // We arbitrarily decide that Infinity is integral.
  if (!match) {
    return 0;
  }
  return Math.max(
    0, // lower limit.
    (match[1] == "0" ? 0 : (match[1] || "").length) - // fraction length
      (match[2] || 0)
  );
}
